.container{
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-secondary-color);
}

.section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
    overflow: hidden;
    background-color: var(--background-secondary-color);
}

.sectionInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    flex-wrap: nowrap;
    gap: 1rem;
}

.sectionTitle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: .5rem;
}

.sectionTitle > button {
    font-size: 1rem;
}


.banner {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
}

.bannerLeft{
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}

.bannerRight{
    display: flex;
    width: 50%;
    height: 400px;
    gap: 1rem;
}

.courses{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.courseCardWrapper{
    display: flex;
    width: 33%;
    justify-content: center;
    padding: 0.5rem;
}

@media screen and (max-width: 1439px) {
    .courseCardWrapper{
        display: flex;
        width: 33%;
    }
}

@media screen and (max-width: 1140px) {
    .courseCardWrapper{
        display: flex;
        width: 33%;
    }
}

@media screen and (max-width: 768px) {
    .courseCardWrapper{
        display: flex;
        width: 50%;
        max-width: none;
    }

    .bannerRight {
        display: none;
    }

    .bannerLeft {
        width: 100%;
    }
}


@media screen and (max-width: 540px) {
    .container{
        padding: 0rem;
    }

    section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;
        margin: 0;
    }

    .sectionInner {
        padding: 1rem;
    }

    .header{
        width: 100%;
        padding: 1rem;
    }

    .courseCardWrapper{
        display: flex;
        width: 100%;
        max-width: none;
    }
}