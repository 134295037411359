.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    background-color: var(--background-secondary-color);
}

.section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
    overflow: hidden;
}

.sectionInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    flex-wrap: nowrap;
    gap: 1rem;
}

.banner {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
}

.bannerLeft {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}

.bannerRight {
    display: flex;
    width: 50%;
    height: 400px;
    gap: 1rem;
    justify-content: center;
}

.careers {
    display: flex;
    width: 100%;
}

.column {
    display: flex;
    width: 33.33%;
    padding: 0.5rem;
    flex-direction: column;
    gap: 1rem;
}

.myCareer {
    display: flex;
    flex-direction: row;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    gap: 1rem;
    background-color: var(--background-color);
    position: relative;
    overflow: hidden;
}

.myCareerHeader {
    display: flex;
    flex-direction: column;
    gap: .25rem;
}

.myCareerInfos {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    justify-content: space-between;
}

.myCareerDescription{
    font-family: var(--primary-font);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    height: 4.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.myCareerThumbnail {
    object-fit: contain;
    max-height: 224px;
    display: flex;
}

.gradient {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: radial-gradient(circle at 90% 50%, rgba(255, 214, 0, 20%) 0, transparent 50%);
}

@media screen and (max-width: 1170px) {

    .column {
        width: 50%;
    }

}

@media screen and (max-width: 1024px) {

    .sectionInner {
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .bannerRight {
        display: none;
    }

    .bannerLeft {
        width: 100%;
    }
}

@media screen and (max-width: 540px) {
    .container {
        padding: 0;
    }

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 1rem;
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }

    .column {
        width: 100%;
    }

    .myCareer {
        flex-direction: column-reverse;
    }
}