.container {
    display: flex;
    flex-direction: row;
    border-radius: 14px;
    background-color: white;
    overflow: hidden;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    padding: 1rem;
    gap: 1rem;
    width: 100%;
}

.comingSoon {
    filter: grayscale(1);
    opacity: 0.85;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    gap: 1rem;
}

.right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.thumbnail {
    display: flex;
    width: 230px;
    height: 130px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
}

.thumbnail>img {
    display: flex;
    object-fit: cover;
    width: 100%;
}


.info {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: .5rem;
}

.description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3.6em;
    /* (line-height * numero di righe) + eventuali padding/margini */
    overflow: hidden;
    text-overflow: ellipsis;
}

.progressContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.progressInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.numbers {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.number {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    align-items: center;
    font-weight: 700;
}

.percentage {
    color: var(--tertiary);
    font-size: 1rem;
    font-weight: 700;
}

.progressBar {
    background-color: rgba(0, 0, 0, 0.1);
    height: 10px;
    width: 100%;
    border-radius: 10px;
    position: relative;
}

.progressFill {
    position: absolute;
    left: 0;
    top: 0;
    height: 10px;
    border-radius: 10px;
    background-color: var(--tertiary);
}

.nextLessons {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: .5rem;
    flex-wrap: wrap;
}

.nextLesson {
    display: flex;
    background-color: rgba(var(--text-color-rgb), 4%);
    padding: 2px;
    flex-direction: row;
    border-radius: 8px;
    flex-basis: 30%;
    align-items: center;
}

.nextLessonDate {
    height: 44px;
    width: 44px;
    min-width: 44px;
    min-height: 44px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: var(--background-color);
    align-items: center;
    justify-content: center;
}

.nextLessonMonth {
    display: flex;
    color: var(--primary);
    font-size: 0.625rem;
    font-weight: 800;
}

.nextLessonDay {
    color: rgba(var(--text-color-rgb), 88%);
    font-size: 1.125rem;
    font-weight: 800;
    line-height: 100%;
}

.nextLessonInfo {
    padding: 0 .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.nextLessonWeekDay {
    font-size: 0.75rem;
    color: rgba(var(--text-color-rgb), 48%);
    font-weight: 700;
}

.nextLessonHours {
    font-weight: 700;
    color: rgba(var(--text-color-rgb), 88%);
    font-size: 1rem;
    white-space: nowrap;
}

@media screen and (max-width: 768px) {

    .nextLesson {
        flex-basis: 48%;
    }
}

@media screen and (max-width: 540px) {

    .container {
        flex-direction: column;
    }

    .nextLesson {
        flex-basis: 100%;
    }

    .thumbnail {
        width: 100%;
        height: 100%;
        aspect-ratio: 16/9;
    }

    .description {
        height: 3.35rem;
    }
   
}
