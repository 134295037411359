.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
}

.inactive {
    text-decoration: none;
    color: rgba(var(--text-color-rgb), 72%);
}

@media (hover: hover){

    .inactive:hover {
        color: var(--primary);
    }

}