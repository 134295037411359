.container{
    min-height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 3rem;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: var(--background-color);
    gap: 1rem;
    justify-content: space-between;
}

.centered {
    justify-content: center;
}

@media screen and (max-width: 540px) {
    .container {
        min-height: 60px;
        padding: 0 1rem;
    }
}