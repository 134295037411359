.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  min-height: calc(100dvh - 80px);
}


.survey {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  height: calc(100dvh - 88px);
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.survey::-webkit-scrollbar {
  display: none;
}

.top {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2rem;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.groupProgress {
  min-width: 75%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}


.groupName {
  font-weight: 800;
  font-size: 1.125rem;
  color: var(--tertiary);
}

.question {
  text-align: center;
  font-weight: 800;
  font-size: 2rem;
}

.slideContainer {
  width: 100%;
  height: 80dvh;
  max-width: 80vw;
}

.options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  max-width: 768px;
  /* margin: 0 auto; */
  overflow-y: scroll;
  max-height: 100%;
  width: 100%;
  user-select: none;
}

.option {
  padding: 32px;
  border-radius: 16px;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  background-color: var(--background-color);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  transition: all .2s ease;
  cursor: pointer;
  min-width: 144px;
  color: var(--primary);
  width: 100%;
  max-width: 400px;
}

.openOptionText {
  color: var(--primary);
  font-size: 0.875rem;
  font-weight: 700;
}

.option.selected,
.openOptionCard.selected,
.numericOption.selected {
  border: 1px solid var(--primary);
  background-color: rgba(var(--primary-rgb), 6%);
}

.option.discarded,
.openOptionCard.discarded,
.numericOption.discarded {
  color: rgba(var(--text-color-rgb), 40%);
  background-color: var(--background-secondary-color);
}

.openOptionText.selected {
  color: var(--primary);
}

.openOptionText.discarded {
  color: rgba(var(--text-color-rgb), 40%);
}

.numericOptions {
  display: flex;
  padding: 24px 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  max-width: 768px;
  overflow-y: scroll;
  max-height: 100%;
  width: 100%;
  user-select: none;
}

.numericOption {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  min-height: 48px;
  min-width: 48px;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  background-color: var(--background-color);
  text-align: center;
  color: var(--primary);
  font-weight: 800;
  font-size: 1.125rem;
  cursor: pointer;
  transition: .2s all ease;
}

.pagination {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.pageButtons {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.groupContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
}

.questionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.innerSplideContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.openContainer {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
}

.openOption {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
  max-width: 400px;
}

.openOptionCard {
  display: flex;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 16px;
  padding: 0.5rem 1rem;
  background-color: var(--background-color);
  overflow: hidden;
}

.helperText {
  font-weight: 700;
}

.textAreaContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.textArea {
  border: none;
  padding: .5rem 0;
  display: flex;
  width: 100%;
  /* min-width: 512px; */
  border-radius: 14px;
  background-color: transparent;
  resize: none;
  font-family: var(--primary-font);
  font-size: 1rem;
  font-weight: 600;
  flex: 1 1;
  background-color: transparent;
  color: var(--text-color);
  min-height: 196px;
}

.textArea.small {
  min-height: 96px;
}

.textArea:focus {
  outline: none;
}

.textArea::placeholder {
  color: rgba(var(--text-color-rgb), 12%)
}

.bull {
  position: relative;
  overflow: visible;
}

.confetti {
  display: block;
  position: absolute;
  top: calc(-50% - 64px);
  left: calc(-145%);
  width: 800px;
  height: 100dvh;
  z-index: 99998;
  overflow: visible;
  pointer-events: none;
}

.confetti canvas {
  width: 100%;
  height: 100%;
}

.spacer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  background: linear-gradient(0deg, var(--background-color) 0%, transparent 70%);
  z-index: 99999;
}

.registration {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.registrationInputs {
  width: 40%;
  min-width: 512px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  overflow: visible;
}

.registrationSection {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: center;
  justify-content: center;
  overflow: visible;
  width: 100%;
}

.registrationTitle {
  font-weight: 600;
  color: rgba(var(--text-color-rgb), 88%);
  align-self: flex-start;
}

.box {
  padding: 1rem 1.5rem;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 16px;
  color: var(--primary);
  text-transform: uppercase;
  transform: all .2s ease;
  font-weight: 800;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.box.selected {
  background-color: rgba(var(--primary-rgb), 6%);
  border: 1px solid var(--primary);
}

.box>svg {
  margin-right: 4px;
}

.genders {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.genders .box {
  flex-basis: 33.33%;
}

.genders .box:nth-child(2) {
  margin: 0 0.5rem;
}

.employments {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-bottom: 1rem;
}

.actionButton {
  padding: .85rem 2rem;
}

@media (hover:hover) {

  .option:hover,
  .numericOption:hover {
    color: var(--primary);
    border: 1px solid var(--primary);
  }

  .box:hover {
    border: 1px solid var(--primary);
  }
}

@media screen and (max-width: 768px) {

  .container {
    min-height: 100dvh;
  }


  .slideContainer {
    max-width: 90vw;
  }

  .top {
    max-height: calc(100dvh - 72px);
  }

  .registrationInputs {
    display: flex;
    width: 100%;
    min-width: unset;
  }

  .actionButton {
    padding: .7rem 1.5rem;
  }
}

@media screen and (max-width: 540px) {

  .actionButton {
    padding: .5rem 1rem;
  }
}