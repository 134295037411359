.container {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-bottom: 3rem;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--background-secondary-color);
}

.section {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.sectionInner {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.overview {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: .5rem;
}

.featureRow {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
    padding: .5rem 0;
}

.feature {
    display: flex;
    font-weight: 600;
    color: rgba(var(--text-color-rgb), 0.6);
    align-items: center;
    line-height: 1.5rem;
    gap: .3rem;
    white-space: nowrap;
}

.feature>svg {
    height: 20px;
    width: 20px;
}

.feature>svg>g>path {
    stroke: rgba(var(--text-color-rgb), 0.6);
}

.priceRow {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
}

.discount {
    display: flex;
    flex-direction: row;
    color: #FF8652;
    font-weight: 500;
    justify-content: center;
}

.discount>div {
    text-decoration: line-through;
    padding-right: .3rem;
    font-weight: 700;
}

.actionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
}

.actionRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    padding: .5rem 0;
    flex-wrap: wrap;
}

.actionRow>div {
    display: flex;
    min-width: 190px;
    max-width: 250px;
    flex: 1;
}

.supportButton {
    color: #4CB4FF;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(45deg, rgba(76, 180, 255, 1) 0%, rgba(203, 102, 250, 1) 61%, rgba(250, 102, 118, 1) 100%) border-box;
    border-radius: 50em;
    border: 2px solid transparent;
    padding: .5rem;
}

.playerWrapper {
    display: flex;
    aspect-ratio: 21 / 9;
    border-radius: 12px;
    overflow: hidden;
    height: fit-content;
    margin-bottom: 1rem;
    width: 100%;
    background-color: black;
}

.player {
    border-radius: 12px;
    overflow: hidden;
    max-width: 98vw;
    width: 100%;
}

.classesRow {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
}

.groups {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
    padding: 1rem 0;
    max-width: calc(100vw - 6rem - 90px);
    scrollbar-width: 0;
}

.groups.sideNavOpen {
    max-width: calc(100vw - 6rem - 300px);
}

.groups::-webkit-scrollbar{
    display: none;
}

.groupCard {
    display: inline-block;
    margin-right: 0.5rem;
    cursor: pointer;
}


.infoCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
}

.infoCard {
    display: flex;
    width: 50%;
    padding: .5rem;
}

.program {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    height: fit-content;
    transition: .3s height ease-in;
    width: 100%;
}

.reviewSubtitle {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.teachersDetail {
    width: 100%;
    justify-content: flex-start;
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.teacherDetailPicture {
    width: 160px;
    height: 160px;
    border-radius: 100px;
    object-fit: cover;
    border: 5px solid var(--secondary);
    padding: 5px;
}

.teacherDetailInfo {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    gap: .5rem;
    font-family: var(--primary-font);
}

.teacherDetailName {
    font-weight: 700;
    font-size: 1.15rem;
}

.teacherDetailQualification {
    color: rgba(var(--text-color-rgb), 0.6);
    font-size: 1.15rem;
    font-family: var(--primary-font);
}

.teacherDetailTags {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    flex-wrap: wrap;
    font-family: var(--primary-font);
}

.teacherDetailTag {
    font-weight: 800;
    font-size: 0.8rem;
    padding: 0.2rem .4rem;
    border-radius: 8px;
}

.stickyBar {
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem;
    top: 79px;
    height: 80px;
    background-color: var(--background-color);
    z-index: 3;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    gap: 1rem;    
    transition: all .1s ease-in;
}

.stickyBarTitle{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3.6em; /* (line-height * numero di righe) + eventuali padding/margini */
    overflow: hidden;
    text-overflow: ellipsis;
}

.stickyBar.hidden{
    background-color: transparent;
    border-bottom: 0px solid;
    transition: all .1s ease-in;
}

.floatingSupport{
    display: flex;
    position: fixed;
    bottom: 1.5rem;
    right: 2rem;
    z-index: 3;
}

.floatingSupportButton {
    color: #4CB4FF;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(45deg, rgba(76, 180, 255, 1) 0%, rgba(203, 102, 250, 1) 61%, rgba(250, 102, 118, 1) 100%) border-box;
    border-radius: 50em;
    border: 2px solid transparent;
    padding: .5rem;
}

.teachersCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 320px;
}

.reviewsCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 450px;
}


@media (hover: hover) {
    .supportButton:hover {
        background: linear-gradient(45deg, rgba(76, 180, 255, 1) 0%, rgba(203, 102, 250, 1) 61%, rgba(250, 102, 118, 1) 100%) border-box;
        border: 2px solid transparent;
        transition: all .2s ease-in-out;
        color: white;
        box-shadow: none;
    }

    .supportButton:hover>.supportButtonInner {
        background: transparent;
        transition: all .2s ease-in-out;
        color: white;
    }

    .supportButton:hover> svg> path {
        background: transparent;
        transition: all .2s ease-in-out;
        fill: white;
    }

    .floatingSupportButton:hover {
        background: linear-gradient(45deg, rgba(76, 180, 255, 1) 0%, rgba(203, 102, 250, 1) 61%, rgba(250, 102, 118, 1) 100%) border-box;
        border: 2px solid transparent;
        transition: all .2s ease-in-out;
        color: white;
        box-shadow: none;
    }

    .floatingSupportButton:hover>.supportButtonInner {
        background: transparent;
        transition: all .2s ease-in-out;
        color: white;
    }

    .floatingSupportButton:hover> svg> path {
        background: transparent;
        transition: all .2s ease-in-out;
        fill: white;
    }

}

@media screen and (max-width: 1280px) {

    .overlayInner{
        width: 50vw;
    }
}

@media screen and (max-width: 1024px) {

    .sectionInner {
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }

    .overviewRight {
        min-width: 0;
    }

    .playerWrapper {
        display: flex;
        aspect-ratio: 16/9;
    }

    .teachers{
        max-width: none;
    }

    .infoCard {
        width: 100%;
    }

    .actionWrapper{
        flex-direction: column;
        gap: 0;
    }
    
}

@media screen and (max-width: 980px) {
    .teacherCard{
        width: 100%;
    }

    .overlayInner{
        width: 70vw;
    }
}

@media screen and (max-width: 768px) {

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 2rem;
        flex-direction: column;
    }

    .groups {
        max-width: calc(100vw - 4rem);
    }

    .stickyBar {
        padding: 0 1rem;
        top: 69px;
        height: 69px;
        width: 100%;
    }

    .infoCards {
        flex-direction: row;
    }

    .infoCard {
        width: 50%;
    }

    .floatingSupport {
        display: flex;
        position: fixed;
        bottom: .5rem;
        right: 1rem;
        z-index: 3;
    }

    .floatingSupportButton {
        background: linear-gradient(45deg, rgba(76, 180, 255, 1) 0%, rgba(203, 102, 250, 1) 61%, rgba(250, 102, 118, 1) 100%) border-box;
        border: 2px solid transparent;
        transition: all .2s ease-in-out;
        color: white;
        box-shadow: none;
        padding: 1rem !important;
    }

    .floatingSupportButton>.supportButtonInner {
        background: transparent;
        transition: all .2s ease-in-out;
        color: white;
    }

    .floatingSupportButton> svg> path {
        background: transparent;
        transition: all .2s ease-in-out;
        fill: white;
    }

    .overlayInner{
        width: 80vw;
    }

    .actionRow > div {
        max-width: none;
        width: 50%;
    }
}

@media screen and (max-width: 540px) {
    .container {
        padding: 0;
    }

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 1rem;
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }

    .actionRow {
        flex-direction: column;
    }

    .actionRow>button {
        width: 100%;
    }

    .actionRow > div {
        width: 100%;
    }

    .supportButton>div {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .groups {
        max-width: calc(100vw - 2rem);
    }

    .infoCard{
        width: 100%;
    }

    .programElem{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .5rem 0;
    }

    .teachersDetail{
        flex-direction: column;
        align-items: center;
    }
    
    .stickyBar > div > div{
        font-size: 1rem;
    }

    .overlayInner{
        width: 95vw;
    }

    .reviews, .teachers {
        padding-left: 0;
        padding-right: 0;
    }

    .reviews > .sectionInner,
    .teachers > .sectionInner {
        padding-left: 0;
        padding-right: 0;
    }
}
