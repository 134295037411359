.cardInner {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    position: relative;
    height: 100%;
    width: 100%;
}

.bannerImage {
    display: flex;
    width: 100%;
    height: 190px;
}

.bannerImage > img {
    padding-top: 2rem;
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 1rem;
    z-index: 0;
}

.bannerInfo {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 2.5rem 2.5rem 2.5rem;
    z-index: 1;
    justify-content: flex-end;
}

.call {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
    height: 100%;
}

.callRows {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
    width: 100%;
}

.callRow {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.spacer {
    display: flex;
    flex: 1;
    height: 100%;
}

.content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.status {
    text-transform: uppercase;
    border-radius: 4px;
    padding: 0 6px;
    border: 2px solid rgba(var(--primary-rgb), 1);
    width: fit-content;
    font-weight: 800;
}

.pending {
    color: var(--secondary);
    border: 2px solid rgba(var(--secondary-rgb), 1);
}

.confirmed {
    color: var(--sf-green);
    border: 2px solid rgba(var(--sf-green-rgb), 1);
}

.completed {
    color: var(--primary);
    border: 2px solid rgba(var(--primary-rgb), 1);
}


.admin {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    gap: .25rem;
}

.adminPicture {
    display: flex;
    object-fit: cover;
    padding: 2px;
    height: 57px;
    width: 57px;
    border-radius: 100px;
    border: 2px solid rgba(var(--primary-rgb), 1);
}

@media screen and (max-width: 540px) {
    
    .bannerImage {
        display: flex;
        width: 100%;
        height: 140px;
        position: absolute;
        top: 0;
    }

}