.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    background-color: white;
    height: fit-content;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    container-type: inline-size;
}

.header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    transition: .2s all ease-in-out;
    border: none;
    background-color: white;
    cursor: pointer;
    font-family: var(--primary-font);
    font-weight: 700;
    font-size: 1.15rem;
    color: black;
    text-align: left;
    gap: .5rem;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: rgba(var(--text-color-rgb), 12%);
}

.header.expanded {
    background-color: rgba(var(--primary-rgb), 4%);
    transition: .2s all ease-in-out;
}

.chevronIcon {
    transform: scaleY(-1);
    transition: all .2s ease-in-out;
    min-width: 20px;
}

.chevronIcon.expanded {
    transition: all .2s ease-in-out;
    transform: scaleY(1);
}

.content {
    max-height: 0;
    padding: 0 2rem;
    transition: .2s all ease-in-out;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: var(--primary-font);
}

.content.expanded {
    max-height: 1000px;
    transition: .2s all ease-in-out;
    padding: 2rem;
}

.argument {
    width: 50%;
    font-size: 1rem;
    font-weight: 600;
    padding: .5rem;
    display: none;
    gap: .5rem;
}

.argument.expanded {
    display: flex;
}

@media screen and (max-width: 768px) {

    .header {
        padding: 0 1rem;
        font-size: 1rem;
    }

    .content {
        flex-direction: column;
    }

    .argument {
        width: 100%;
    }

}

@media screen and (max-width: 540px) {}