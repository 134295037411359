.container {
    display: flex;
    min-width: 220px;
    width: 220px;
    min-height: 120px;
    height: 120px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    background-color: black;
    /* cursor: pointer; */
}

.screenShareBadge {
    display: flex;
    height: 24px;
    width: 24px;
    background-color: #4bc9a3;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    padding: .25rem;

    position: absolute;
    top: 50%;
    right: .5rem;
    transform: translateY(-50%);
}

.micBadge > svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
}

.micBadge {
    display: flex;
    height: 24px;
    width: 24px;
    background-color: rgba(var(--background-color-rgb), 10%);
    border-radius: 12px;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: .5rem;
    right: .5rem;
}

.micBadge > svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
}

.micBadge.disabled {
    background-color: #F43A3A;
}

.micBadge.disabled > svg > path {
    fill: var(--background-color);
}

.settingsBadge {
    display: flex;
    height: 24px;
    width: 24px;
    background-color: rgba(var(--background-color-rgb), 10%);
    border-radius: 12px;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: .5rem;
    right: .5rem;
    cursor: pointer;
    transition: all .1s ease-in-out;
    color: white;
}

.settingsBadge > svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
}

.settingsBadge:hover {
    background-color: rgba(var(--background-color-rgb), 100%);
    color: rgba(var(--text-color-rgb), 88%);
    transition: all .1s ease-in-out;
}

.name {
    position: absolute;
    font-family: var(--primary-font);
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--background-color);
    padding: .5rem 1rem;
    bottom: .15rem;
    left: .15rem;
    background-color: rgba(0,0,0,64%);
    border-radius: 8px;
}

.overlay {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(var(--text-color-rgb), 60%);
    padding: .5rem;
    justify-content: space-between;
}

.overlayHeader {
    display: flex;
    width: 100%;
    color: white;
    font-weight: 700;
    justify-content: space-between;
    align-items: center;
    padding: .125rem .25rem;
}
.audioGradient {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    pointer-events: none;
    transition: all .3s ease-in-out;
}