.container {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-bottom: 3rem;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--background-secondary-color);
}

.section {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.setcion.bordered {
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
}

.sectionInner {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    flex-wrap: wrap;
    /* gap: 2rem; */
}

.careerLeft {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 360px;
    flex: 1;
    padding: 0 1rem;
}

.careerRight {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    gap: .5rem;
    padding: 0 1rem;
    min-width: 420px;
}

.playerWrapper {
    display: flex;
    aspect-ratio: 16 / 9;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: fit-content;
}

.mobilePlayerWrapper {
    aspect-ratio: 16 / 9;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    display: none;
}

.player {
    border-radius: 12px;
    overflow: hidden;
    max-width: 98vw;
}

.salary {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    padding: .3rem 0;
    align-items: center;
    margin: .5rem 0;
}

.salaryLabel {
    font-size: 0.75rem;
    font-weight: 600;
    color: rgba(var(--text-color-rgb), 40%);
    padding-left: .3rem;
}

.salaryValue {
    font-size: 0.75rem;
    font-weight: 800;
    color: rgba(var(--text-color-rgb), 60%);
    padding-left: 1.5rem;
}

.spacer {
    display: flex;
    flex: 1;
}

.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .5rem
}

.courseLeft {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
    padding: 0 1rem;
}

.courseRight {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
}

.featureRow {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
    padding: .5rem 0;
}

.feature {
    display: flex;
    font-weight: 600;
    color: rgba(var(--text-color-rgb), 0.6);
    align-items: center;
    line-height: 1.5rem;
    gap: .3rem;
    white-space: nowrap;
}

.feature>svg {
    height: 20px;
    width: 20px;
}

.feature>svg>g>path {
    stroke: rgba(var(--text-color-rgb), 0.6);
}

.feature>svg>path {
    stroke: rgba(var(--text-color-rgb), 0.6);
}

.priceRow {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    flex-wrap: wrap-reverse;
}

.discount {
    display: flex;
    flex-direction: row;
    color: #FF8652;
    font-weight: 500;
    justify-content: center;
    flex-wrap: nowrap;
}

.discount>div {
    text-decoration: line-through;
    padding-right: .3rem;
    font-weight: 700;
}

.actionRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    padding: .5rem 0;
    flex-wrap: wrap;
}

.actionRow>div {
    display: flex;
    width: 50%;
}

.thumbnail {
    aspect-ratio: 16/9;
    object-fit: cover;
    display: flex;
    width: 100%;
    border-radius: 12px;
}

.thumbnailMobile {
    aspect-ratio: 16/9;
    object-fit: cover;
    display: none;
    width: 100%;
    border-radius: 12px;
}

#sectionInner {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.sectionTitle {
    padding: 0 3rem;
}

.partners {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    height: 132px;
    position: relative;
}

.partnersTrack {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    position: absolute;
    overflow: scroll;
    left: 0;
    right: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 3rem;
}

.partnersTrack::-webkit-scrollbar {
    display: none;
}

.partnerCard {
    display: flex;
    min-width: 250px;
    width: 250px;
    min-height: 120px;
    height: 120px;
    background-color: #F8F5F5;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 12px;
    padding: 1rem;
}

.partnerLogo {
    width: 100%;
    object-fit: contain;
    max-height: 80px;
}

.courses {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 450px;
    position: relative;
}

.coursesTrack {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    position: absolute;
    overflow: scroll;
    left: 0;
    right: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 3rem;
}

.coursesTrack::-webkit-scrollbar {
    display: none;
}

.courseCard {
    min-width: 320px;
    max-width: 320px;
}

.articles {
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.bigArticle {
    display: flex;
    width: 50%;
    flex: 1;
    padding: 0 .5rem;
}

.smallArticles {
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 .5rem;
}

.articleCard {
    min-width: 420px;
}

@media screen and (max-width: 1180px) {
    .playerWrapper {
        display: none;
    }

    .mobilePlayerWrapper {
        display: flex;
    }

    .careerRight {
        width: 100%;
    }

    .careerLeft {
        display: none;
    }

    .articles {
        flex-direction: column;
        gap: 1rem;
    }

    .bigArticle,
    .smallArticles {
        width: 100%;
        padding: 0;
        gap: 1rem;
    }
    
    .articleCard{
        min-width: 0;
    }

}

@media screen and (max-width: 1024px) {
    .courseLeft {
        width: 100%;
    }

    .thumbnail {
        display: none;
    }

    .thumbnailMobile {
        display: flex;
    }
}

@media screen and (max-width: 768px) {

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 2rem;
        flex-direction: column;
    }

    .careerLeft {
        display: none;
    }

    .careerRight {
        min-width: 0;
    }

    .careerRight,
    .courseLeft,
    .courseRight {
        padding: 0;
        width: 100%;
    }

    #sectionInner {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .partnersTrack,
    .coursesTrack,
    .sectionTitle {
        padding: 0 2rem;
    }

}

@media screen and (max-width: 540px) {

    .sectionInner {
        padding: 1rem;
    }

    .actionRow>div {
        width: 100%;
    }

    .partnersTrack,
    .coursesTrack,
    .sectionTitle {
        padding: 0 1rem;
    }
}