.header {
    display: flex;
    flex-direction: row;
    padding: 1rem 1.25rem;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
}

.headerIcon {
    display: flex;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
}

.headerIcon>img {
    width: 100%;
    height: 100%;
}

.careerName {
    display: flex;
    flex-grow: 1;
    width: 100%;
    font-weight: 700;
    font-size: 1.125rem;
    white-space: break;
}

.content {
    display: flex;
    flex-direction: row;
    padding: 1rem 1.25rem 2rem 1.25rem;
    width: 100%;
}

.questionBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 .5rem;
}

.questionTitle {
    font-size: 0.875rem;
    font-weight: 500;
    color: rgba(var(--text-color-rgb), 40%);
    font-family: var(--primary-font);
}

.questionAnswer {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    align-items: center;
}

.questionAnswerIcon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
}

.questionAnswerLabel {
    font-weight: 700;
    font-size: 1rem;
    color: rgba(var(--text-color-rgb), 88%);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 2.5rem;
    /* (line-height * numero di righe) + eventuali padding/margini */
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--primary-font);
}

.retryButton {
    color: var(--text-color);
    background-color: transparent;
    background: none;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.75rem;
    gap: .2rem;
    cursor: pointer;
    padding: .35rem .5rem;
}

@media screen and (max-width: 768px) {

    .content {
        flex-wrap: wrap;
    }

    .questionBox {
        padding: .5rem;
        flex-basis: 50%;
    }

}

@media screen and (max-width: 540px) {

    .content {
        flex-wrap: wrap;
    }

    .questionBox {
        padding: .5rem;
        flex-basis: 100%;
    }

    .retryButton {
        padding: 1rem;
    }

    .retryButton > svg {
        width: 16px;
        height: 16px;
    }

}