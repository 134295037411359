.teachers {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    height: fit-content;
    min-height: 32px;
}

.centered {
    display: flex;
    width: 100%;
    padding: 0 1rem;
}

.teacherPicture {
    width: 32px;
    height: 32px;
    border-radius: 100px;
    object-fit: cover;
    border: 1.5px solid var(--secondary);
    padding: 2px;
    background-color: var(--background-color);
}

.absolute {
    position: absolute;
}

.teacherInfo {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    justify-content: center;
}

.teacherName {
    font-weight: 700;
    font-size: 1rem;
    white-space: nowrap;
}

.teacherQualification {
    color: rgba(var(--text-color-rgb), 0.6);
    font-size: .75rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3.6em;
    /* (line-height * numero di righe) + eventuali padding/margini */
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--primary-font);
}

.moreTeachers {
    border: 1.5px solid var(--primary);
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 100px;
    padding: 2px;
    background-color: var(--background-color);
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 600;
    transform: translateX(-160%);
    z-index: 8;
}