.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 1rem;
    gap: 4px;
    background-color: white;
    align-items: center;
    padding: .9rem;
    cursor: pointer;
    font-size: 1rem;
}

.container.open {
    border-radius: 1rem 1rem 0rem 0rem;
}

.selectedLabel {
    display: flex;
    box-sizing: border-box;
    font-family: var(--primary-font);
    font-weight: 700;
    letter-spacing: 0.5%;
    background-color: transparent;
    flex: 1;
}

.options {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 25px;
    left: -1px;
    right: -1px;
    max-height: 150px;
    z-index: 99;
    background-color: white;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 0 0 1rem 1rem;
    font-family: var(--primary-font);
    font-weight: 700;
    letter-spacing: 0.5%;
    overflow: scroll;
    padding: .5rem;
    gap: 4px;
}

.option {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: .5rem;
    border-radius: 8px;
}

.option:hover {
    background-color: rgba(var(--primary-rgb), 10%);
}

.option.selected {
    background-color: rgba(var(--primary-rgb), 40%);
}

.chevron {
    position: relative;
    height: 16px;
    width: 16px;
    transform: rotateX(180deg);
    cursor: pointer;
    transition: .2s all ease-in-out;
}

.chevron.open {
    transform: rotateX(0);
    transition: .2s all ease-in-out;
}

.container:hover{
    border: 1px solid var(--primary);
}

.container:hover .options {
    border: 1px solid var(--primary);   
}