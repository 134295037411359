
.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0 3rem;
}

.title, .body{
    display: flex;
    width: 100%;
    text-align: left;
}

.formContainer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 1rem 0 0.5rem 0 ;
}

.resetPassword{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.textDivider{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 1rem;
    color: rgba(var(--text-color-rgb), 40%);
    padding: 1rem 0;
}

.divider{
    display: flex;
    width: 100%;
    background-color: rgba(var(--text-color-rgb), 12%);
    height: 1px;
}

.socialLogin{
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: space-around;
}

.googleLogo{
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.googleSignIn{
    color: var(--text-color);
    background: rgb(242,242,242);
    font-family: var(--primary-font);
    font-weight: 500;
    border: none;
    border-radius: 2rem;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
    width: 100%;
    justify-content: center;
}

.appleLogo{
    width: 20px;
    height: 20px;
    object-fit: contain;
    fill: white;
}

.appleLogo > svg {
    fill: white;
}

.appleSignIn{
    background: black;
    color: white;
    font-family: var(--primary-font);
    font-weight: 500;
    border: none;
    border-radius: 2rem;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
    width: 100%;
    justify-content: center;
}

.spacer{
    flex: 1;
}

.registerContainer{
    display: flex;
    font-size: .9rem;
    align-items: center;
}

.error{
    color: red;
    display: flex;
    width: 100%;
    text-align: left;
    font-weight: 600;
}

@media screen and (max-width: 768px) {

    .logoContainer{
        max-width: 100%;
        padding: 1.5rem 1rem;
        border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    }
    
    .logo{
        width: 9rem;
    }

    .container{
        justify-content: flex-start;
        padding: 1rem 1rem 2rem 1rem;
    }

    .spacer{
        flex: 0;
    }

}

@media screen and (max-width: 540px) {
    .socialLogin{
        flex-direction: column;
    }
}