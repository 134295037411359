.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stripeElement{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.stripeElement > div {
    width: 95%;
    padding: 4px;
}

.arrow{
    transform: rotate(180deg);
}

.paymentError {
    background-color: var(--sf-red);
    padding: .5rem 1rem !important;
    border-radius: 8px;
    margin-top: 1rem;
    color: white;
    font-size: 0.875rem;
}

.couponLabel {
    font-family: 'Work Sans';
    color: var(--text-color);
    font-size: 14.88px;
    font-weight: 400;
}

.couponInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.coupon {
    width: 100%;
    gap: .25rem;
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
    padding-bottom: .5rem;
}

@media screen and (max-width: 768){
   
    .coupon {
        padding: 0 1rem;
        padding-bottom: .5rem;
    }
}