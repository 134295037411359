.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-top: 2rem;
}

.text {
    font-size: 1.125rem;
    font-weight: 600;
}

.words {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 1rem;
    column-gap: .3rem;
    border-radius: 12px;
    border: 1px solid rgba(var(--text-color-rgb), 10%);
    padding: 1.5rem 2rem;
    width: 100%;
    /* font-weight: 600; */
    background-color: var(--background-color);
    color: var(--text-color);
}

.word>p {
    margin: 0;
}

.inputWord {
    padding: .5rem 1rem;
    border-radius: 12px;
    border: 1px solid rgba(var(--text-color-rgb), 10%);
    min-width: 64px;
    background-color: rgba(var(--text-color-rgb), 2%);
    transition: .2s all ease;
    outline-color: var(--sf-light-green);
    outline-width: 1px;
    font-weight: 700;
    font-size: 1rem;
    font-family: var(--primary-font);
}

.inputWord::placeholder {
    color: rgba(var(--text-color-rgb), 24%);
}

.textCompletion {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.hiddenWord {
    padding: .5rem 1rem;
    border-radius: 12px;
    border: 1px solid rgba(var(--text-color-rgb), 10%);
    background-color: rgba(var(--text-color-rgb), 2%);
    transition: .2s all ease;
    outline-color: var(--sf-light-green);
    outline-width: 1px;
    font-weight: 700;
    font-size: 1rem;
}

.hiddenWord.wrong {
    border: 1px solid var(--sf-red);
    background-color: rgba(var(--sf-red-rgb), 2%);
    color: var(--sf-red);
}

.hiddenWord.wrong span:first-child {
    text-decoration: line-through;
}

.hiddenWord.wrong span:nth-child(2) {
    color: var(--text-color);
}

.solutions {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.solutionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: .2rem;
    font-weight: 700;
    font-size: 1.25rem;
    color: rgba(var(--text-color-rgb), 80%);
}

.solutionContainer .solution {
    color: var(--sf-light-green);

}

@media(hover:hover) {
    .inputWord:hover {
        border: 1px solid var(--sf-light-green);
    }
}

@media screen and (max-width: 540px) {
    .words {
        padding: .5rem 1rem;
        /* border: none; */
    }

    .hiddenWord {
        padding: 0.2rem 1rem;
    }

    .textCompletion {
        gap: 2rem;
    }

    .inputWord {
        max-width: 128px;
    }
}