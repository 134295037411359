.container {
    display: flex;
    width: 100%;
}

.container.large {
    flex-direction: column;
}


.thumbnail {
    object-fit: cover;
}

.thumbnail.large {
    height: 200px;
    width: 100%;
    border-radius: 12px;
}

.thumbnail.medium {
    height: 132px;
    border-radius: 10px;
}

.thumbnail.small {
    height: 96px;
    border-radius: 8px;
}

.info {
    display: flex;
    flex-direction: column;
    padding: .5rem 0;
    gap: .5rem;
}

.info.large {
    padding: 1rem 1rem;
}

.info.medium,
.info.small {
    padding: 0 1rem;
}

.info.small {
    gap: .3rem;
}

.title {
    font-size: 1.125rem;
    font-weight: 800;
    color: rgba(var(--text-color-rgb), 88%);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title.small {
    font-size: 1rem;
}

.abstract {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(var(--text-color-rgb), 64%);
    line-clamp: 3;
    font-family: var(--primary-font);
}

.abstract>p {
    margin: 0;
}

.abstract.small {
    -webkit-line-clamp: 2;
    line-clamp: 2;
    max-height: 3.6rem;
    font-size: 0.875rem;
}

.actionRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.readingTime {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(var(--text-color-rgb), 32%);
    gap: .3rem;
    font-size: 0.875rem;
}

.clockIcon>g>path {
    stroke: rgba(var(--text-color-rgb), 32%);
}
