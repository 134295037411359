.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-width: 1440px;
    margin: 0 auto;
}

li {
    margin-bottom: 1rem;
}

.section {
    /* background-color: var(--background-color); */
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.sectionInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.divider {
    display: flex;
    width: 100%;
    height: 2px;
    background-color: rgba(var(--text-color-rgb), 12%);
}

@media screen and (max-width: 1024px) {

    .sectionInner {
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }
}

@media screen and (max-width: 540px) {
    .container {
        padding: 0;
    }

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 1rem;
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }
}