.container {
    display: flex;
    height: 20px;
    width: 100%;
    border-radius: 20px;
    gap: .5rem;
    justify-content: center;
}

.stepContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: .5rem;
    flex: 1;
}

.step {
    height: 4px;
    width: 100%;
    border-radius: 4px;
    background-color: #DFE6E4;

    background: linear-gradient(to right, var(--primary) 50%, #DFE6E4 0);
    background-size: 200% 100%;
    background-position: right;
}

.completed {
    background-position: left !important;
}

.fill {
    animation-name: fillBackground;
    animation-duration: .3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.empty {
    background-position: left;
    animation-name: emptyBackground;
    animation-duration: .3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.step.fill {
    animation-delay: .3s;
}


.number.empty {
    background-position: left;
    animation-name: emptyBackground;
    animation-duration: .3s;
    animation-delay: .3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.number {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 24px;
    min-height: 32px;
    min-width: 32px;
    background-color: #DFE6E4;
    font-weight: 800;
    font-size: 1.125rem;

    background: linear-gradient(to right, var(--primary) 50%, #DFE6E4 0);
    background-size: 200% 100%;
    background-position: right;
}

.number>svg {
    height: 15px;
    width: 15px;
    min-height: 15px;
    min-width: 15px;
    padding: 2px;
    border-radius: 20px;
}

@keyframes fillBackground {
    100% {
        background-position: left;
    }
}

@keyframes emptyBackground {
    100% {
        background-position: right;
    }
}
