.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    background-color: var(--background-secondary-color);
}

.section {
    background-color: var(--background-secondary-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.sectionInner {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    flex-wrap: wrap;
}

.resultNumber {
    font-weight: 600;
    font-size: 1.375rem;
    color: rgba(var(--text-color-rgb), 80%);
    padding: 1rem 0;
}

.courses {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.course {
    width: 30%;
    flex-basis: 30%;
    height: fit-content;
    padding: .5rem;
}

.tags {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem 0;
    flex-wrap: wrap;
}

.careers {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    padding: 1rem 0;
    flex-wrap: wrap;
}

.career {
    flex-basis: 30%;
    min-width: 280px;
}

.articles {
    display: flex;
    padding: 1rem 0;
}

.videos {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    padding: 1rem 0;
    flex-wrap: wrap;
}

.loaderContainer {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1440px) {
    .course {
        width: 50%;
        flex-basis: 50%;
    }
}

@media screen and (max-width: 768px) {

    .section {
        padding: 2rem 0;
    }

    .sectionInner {
        padding: 0 2rem;
    }

    .career {
        flex-basis: 48%;
        min-width: 0;
    }
}

@media screen and (max-width: 540px) {

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 1rem;
    }

    .career {
        flex-basis: 100%;
    }

    .course {
        width: 100%;
        flex-basis: 100%;
    }
}
