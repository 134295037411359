.container {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.star {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-secondary-color);
    border-radius: 4px;
    color: white;
}

.starIcon {
    height: 14px;
    width: 14px;
    min-height: 14px;
    min-width: 14px;
}