.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 80px);
    margin: 0 auto;
    background-color: var(--background-secondary-color);
    overflow: hidden;
}

.contacts {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    background-color: var(--background-color);
    width: 324px;
    min-width: 324px;
    border-right: 1px solid rgba(var(--text-color-rgb), 12%);
    max-height: calc(100vh - 80px);
    padding: 1.5rem 0;
    gap: .5rem;
}

.search {
    padding: 0 1rem;
}

.search>div {
    background-color: var(--background-color);
    height: 40px;
    border-radius: 14px;
}

.search>div>input {
    background-color: var(--background-color);
}

.contactList {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.contactList::-webkit-scrollbar {
    display: none;
}  

.contact {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    cursor: pointer;
    position: relative;
}

.contact.active {
    background-color: rgba(var(--primary-rgb), 10%);
    transition: all .1s ease-in;
}

.tutorBadge {
    position: absolute;
    bottom: .40rem;
    left: .85rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem;
    color: white;
    background-color: var(--sf-light-green);
    border-radius: 6px;
    text-transform: uppercase;
    font-size: 0.5rem;
    font-weight: 800;
    padding: .2rem;
    padding-right: .4rem;
    font-family: var(--primary-font);
}

.adminBadge {
    position: absolute;
    bottom: .40rem;
    left: .85rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem;
    color: white;
    background-color: var(--sf-red);
    border-radius: 6px;
    text-transform: uppercase;
    font-size: 0.5rem;
    font-weight: 800;
    padding: .2rem;
    padding-right: .4rem;
    font-family: var(--primary-font);
}

.teacherBadge {
    position: absolute;
    bottom: .40rem;
    left: .4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem;
    color: white;
    background-color: var(--sf-purple);
    border-radius: 6px;
    text-transform: uppercase;
    font-size: 0.5rem;
    font-weight: 800;
    padding: .2rem;
    padding-right: .4rem;
    font-family: var(--primary-font);
}

.picture {
    object-fit: cover;
    width: 46px;
    height: 46px;
    min-width: 46px;
    min-height: 46px;
    border-radius: 46px;
    padding: 2px;
    border: 2px solid var(--primary);
    background-color: var(--background-secondary-color);
}

.picture.tutor {
    border: 2px solid var(--sf-light-green);
}

.picture.teacher {
    border: 2px solid var(--secondary);
}

.picture.admin {
    border: 2px solid var(--sf-red);
}

.contactInfo {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.contactName {
    font-weight: 600;
    font-size: 1rem;
}

.contactMessageInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
    font-family: var(--primary-font);
}

.lastMessageTime {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--text-color);
}

.contactLastMessage {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-color);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--primary-font);
}

.contactModules {
    opacity: 0.75;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-color);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--primary-font);
}

.unreadBadge{
    width: 18px;
    height: 18px;
    background-color: var(--secondary);
    border-radius: 16px;
    font-size: 0.75rem;
    font-weight: 600;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.skeletonContact {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
}

.skeletonContactInfo {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.chatContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 80px);
    position: relative;
}

.chat {
    height: 100%;
    width: 100%;
    max-height: calc(100vh - 80px);
    position: relative;
    overflow-y: scroll;
    padding: 1rem 1rem 5rem 1rem;
    display: flex;
    flex-direction: column-reverse;
    font-family: var(--primary-font);
}

.header {
    width: 100%;
    height: 82px;
    background-color: var(--background-color);
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .5rem 1rem;
    gap: 1rem;
    position: relative;
}

.headerPicture {
    height: 48px;
    width: 48px;
    border-radius: 48px;
}

.headerInfo {
    display: flex;
    flex-direction: column;
}

.headerName {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    font-size: 1.125rem;
    font-weight: 600;
}

.headerAvailable {
    display: flex;
    flex-direction: row;
    font-size: 0.875rem;
    font-weight: 600;
    align-items: center;
    gap: 0.3rem;
    font-family: var(--primary-font);
}

.available {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background-color: var(--primary);
}

.notAvailable {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background-color: gray;
}

.bottomBar {
    position: absolute;
    bottom: 12px;
    left: 12px;
    right: 12px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.inner {
    background-color: var(--background-color);
    border-radius: 12px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    display: flex;
    flex-direction: row;
    padding: .5rem;
    gap: .5rem;
    align-items: center;
}

.innerFile {
    width: 100%;
    padding: 0.5rem;
    border-radius: 12px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    background-color: var(--background-color);
}

.fileImage {
    aspect-ratio: 1 / 1;
    width: 100%;
    height: 50vh;
    object-fit: contain;
    background-color: var(--background-secondary-color);
    border-radius: 8px;
}

.closeFile{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--background-color);
    border-radius: 20px;
    padding: 4px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.fileButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.messageInput {
    font-family: var(--primary-font);
    background-color: transparent;
    border: none;
    color: var(--text-color);
    font-size: 1rem;
    width: 100%;
    resize: none;
    min-height: 1.5rem;
    max-height: 4rem;
    height: 1.5rem;
}

.messageInput::placeholder {
    color: rgba(var(--text-color-rgb), 20%);
    font-family: var(--primary-font);
}

.messageInput:focus {
    outline: none;
}

.send {
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    background-color: var(--primary);
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.send:disabled {
    cursor: not-allowed;
    background-color: rgba(var(--text-color-rgb), 20%);
}

.messageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: .5rem;
}

.messageContainer.sent {
    align-items: flex-end;
}

.messageContainer.received {
    align-items: flex-start;
}

.messageDay {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 500;
    color: rgba(var(--text-color-rgb), 64%);
    font-size: 0.875rem;
    padding: 1rem;
    /* position: sticky;
    top: 0; */
}

.message {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 60%;
    padding: .5rem;
    font-weight: 600;
    font-size: 1rem;
    overflow-wrap: anywhere;
    white-space: pre-line;
}

.message.sent {
    background-color: #EBFEF9;
    border-radius: 16px 16px 0px 16px;
}

.message.received {
    background-color: var(--background-color);
    border-radius: 16px 16px 16px 0px;
}

.messageImage {
    width: 100%;
    max-width: 350px;
    object-fit: contain;
    border-radius: 8px;
}

.messageBody {
    padding: 0.25rem;
    font-weight: 500;
}

.imageLoader {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.messageTime {
    padding: .2rem;
    font-weight: 400;
    font-size: 0.875rem;
    color: rgba(var(--text-color-rgb), 80%);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .2rem;
}

.reconnecting {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    display: flex;
    padding: .5rem 1rem;
    border-radius: 16px;
    background-color: var(--secondary);
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: var(--background-color);
    z-index: 999;
}

.backToChat {
    display: none;
}

.file {
    display: flex;
    flex-direction: row;
    padding: .5rem 1rem;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex: 1;
    background-color: var(--background-color);
}

.fileInfo {
    display: flex;
    flex-direction: column;
    gap: .3rem;
}

.fileName {
    font-weight: 700;
    font-size: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1.2rem; /* (line-height * numero di righe) + eventuali padding/margini */
    overflow: hidden;
    text-overflow: ellipsis;
}

.fileSize {
    font-weight: 700;
    font-size: 0.75rem;
    color: rgba(var(--text-color-rgb), 48%);
}

.deleteFile {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid rgba(var(--secondary-rgb), 10%);
    border-radius: 10px;
    width: 32px;
    height: 32px;
    background: none;
    cursor: pointer;
}

.deleteIcon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
}

.deleteIcon > path {
    fill: var(--secondary);
}

.downloadFile {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.fullScreenImageContainer {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--text-color-rgb), 80%);
    z-index: 999;
}

.fullScreenImage {
    width: 100%;
    height: 100%;
    padding: 5%;
    object-fit: contain;
}

.player {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.playerWrapper{
    display: flex;
    border-radius: 8px;
    overflow: hidden;
}

.clock {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

@media screen and (max-width: 768px) {

    .container {
        height: calc(100dvh - 70px);
        max-height: calc(100dvh - 70px);
        min-height: calc(100dvh - 70px);
        overflow: hidden;
    }
    
    .contacts {
        width: 100%;
        position: fixed;
        left: 0;
        right: 0;
        top: 70px;
        height: 100%;
    }

    .chatContainer {
        display: none;
    }

    .chatContainer.visible {
        width: 100%;
        height: calc(100dvh - 70px);
        display: flex;
        position: fixed;
        left: 0;
        right: 0;
        top: 70px;
        background-color: var(--background-secondary-color);
    }

    .chatContainer.leftAnimate {
        transition: left .2s ease-in-out;
    }

    .headerPicture{
        width: 35px;
        height: 35px;
    }

    .backToChat {
        display: flex;
        transform: rotate(-90deg);
        cursor: pointer;
        min-width: 20px;
        min-height: 20px;
    }

}

@media screen and (max-width: 540px) {

    .message {
        max-width: 80%;
    }

}
