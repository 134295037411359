.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 100%;
    padding: 1rem;
}

.title {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
}

.choices {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.choice {
    --color: var(--primary);
    --color-rgb: var(--primary-rgb);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    max-width: 110px;
    text-align: center;
}

.valueWrapper {
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.value {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    border-radius: 10rem;
    padding: .5rem;
    width: 50px;
    height: 50px;
    border: 1px solid rgba(var(--color-rgb), 24%);
    cursor: pointer;
}

.value:hover {
    border: 1px solid var(--color);
}

.value.selected {
    background-color: var(--color);
    color: white;
}


@media screen and (max-width: 540px) {

    .container {
        padding: 1rem;
    }

    .progress {
        font-size: 3rem;
    }

    .title {
        font-size: 1.25rem;
    }

    .choices {
        gap: .5rem;
        flex-direction: column-reverse;
        width: 100%;
    }

    .choice {
        max-width: none;
        border: 1px solid rgba(var(--color-rgb), 20%);
        flex-direction: row;
        padding: 1rem;
        border-radius: 1rem;
        cursor: pointer;
        font-weight: 600;
    }

    .choice.selected {
        background-color: var(--color);
        color: white;
    }

    .valueWrapper {
        height: 30px;
    }

    .value {
        border: none;
        padding: 0;
        width: fit-content !important;
        height: fit-content !important;
    }

    .value:hover {
        border: none;
    }

}