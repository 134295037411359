.container {
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
    height: 20px;
    width: 98%;
    border-radius: 20px;
}

.background {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 20px;
    position: relative;
}

.progress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, var(--secondary) 0%, var(--secondary) 100%);
    border-radius: 20px;
}

.step {
    display: flex;
    flex: 1;
    position: relative;
}

/* .step.completed {
    background: linear-gradient(to right, #15E0FF 0%, #1628E7 200%);
} */

.check {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    border-radius: 24px;
    height: 24px;
    width: 24px;
    border: 2px solid #cbcbcb;
    background-color: #e2e2e2;
    z-index: 1;
    --completed-color: #16A7F8;
    --completed-color-border:#91c7fa;
}

.check.completed {
    background: var(--completed-color);
    border: 1.5px solid var(--completed-color-border)
}

.check>svg {
    height: 15px;
    width: 15px;
    min-height: 15px;
    min-width: 15px;
    padding: 2px;
    border-radius: 20px;
    transform: translate(.25px, .5px);
}