.container{
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 12px;
    overflow: hidden;
    background-color: var(--background-color);
    max-width: 350px;
    cursor: pointer;
    width: 100%;
}

.playerWrapper {
    display: flex;   
    width: 100%;
    overflow: hidden;
    height: 100%;
    position: relative;
    height: 170px;
}

.title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
}


.info {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: .5rem;
}

.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .5rem;
}

.playerOverlay {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.playIcon {
    transition: .1s all ease-in-out;
}

@media (hover:hover) {
    .container:hover .playIcon {
        transform: scale(1.2);
        transition: .1s all ease-in-out;
    }
}