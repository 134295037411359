.container {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    background-color: var(--background-secondary-color);
    padding: 1rem;
    height: 400px;
    width: min(500px, 90vw);
    font-family: var(--primary-font);
}

.message {
    display: flex;
    font-size: 1rem;
    padding: 2rem .5rem;
    align-items: center;
    font-weight: 600;
    height: 100%;
}

.user {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    background-color: var(--background-color);
    border-radius: 12px;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.userInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.avatar {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    border-radius: 40px;
}

.initials {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    text-transform: uppercase;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info {
    display: flex;
    flex-direction: column;
    gap: .3rem;
}

.username {
    font-size: .875rem;
    white-space: nowrap;
}

.playerContainer {
    display: flex;   
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 1rem;
    height: 100%;
}

.imageContainer {
    display: flex;
    overflow: hidden;
    height: 100%;
    border-radius: 12px;
    margin-bottom: 1rem;
}


.image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media screen and (max-width: 540px){

    .avatar{
        height: 30px;
        width: 30px;
        min-width: 30px;
        min-height: 30px;
    }

    .user {
        gap: .5rem;
    }

    .userInfo {
        gap: .5rem;
    }

}