.container {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
}

.gradientLeft {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6vw;
    background: linear-gradient(90deg, var(--background-secondary-color) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 2;
}

.gradientRight {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 6vw;
    background: linear-gradient(270deg, var(--background-secondary-color) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 2;
}

.track {
    display: flex;
    position: relative;
    left: 0;
    align-items: center;
    height: 400px !important;
    min-width: 100%;
    width: fit-content;
    height: 100%;
    z-index: 1;
    justify-content: center;
}

.track.animated {
    position: absolute;
    justify-content: flex-start;
    animation: slide 30s linear infinite;
}

.card {
    display: flex;
    width: 400px;
    height: fit-content;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.card.animated {
    min-width: min(400px, 90vw);
    width: min(400px, 90vw);
}

.track.stopped {
    animation-play-state: paused;
}

.overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(var(--text-color-rgb), 32%);
}

.overlayInner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
}


@media(hover: hover) {
    .track:hover {
        animation-play-state: paused;
        overflow-x: scroll;
    }

    .card:hover {
        box-shadow: 0 0 10px 10px rgba(var(--tertiary-rgb), 10%);
        border: 1px solid transparent;
        transition: all .1s ease-in-out;
        transform: scale(1.05);
    }
}

@media screen and (max-width: 1280px) {
    .overlayInner{
        width: 50vw;
    }
}

@media screen and (max-width: 980px) {
    .overlayInner{
        width: 70vw;
    }
}

@media screen and (max-width: 768px) {
    .overlayInner{
        width: 80vw;
    }
}

@media screen and (max-width: 540px) {
    .overlayInner{
        width: 95vw;
    }

    .gradientLeft, .gradientRight {
        width: 3vw;
    }
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-33.33%);
    }
}