.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
    background-color: var(--background-color);
    align-items: center;
    padding: 1rem 2rem;
    z-index: 1;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
}


.info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.copyright {
    color: rgba(var(--text-color-rgb) 88%);
    font-family: var(--primary-font);
    font-weight: 700;
    font-size: 0.8rem !important;
}

.vatNumber {
    color: rgba(var(--text-color-rgb) 64%);
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 0.8rem !important;
}

.logo {
    width: 44px;
    height: 44px;
}


.spacer {
    flex: 1;
}

.social {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

@media screen and (max-width: 540px) {
    .container {
        height: 200px;
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
        justify-content: space-around;
    }

    .info {
        flex-direction: column;
        justify-content: center;
    }

    .company {
        text-align: center;
    }

    .copyright {
        padding-bottom: .2rem;
    }
}
