.container {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-bottom: 3rem;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--background-secondary-color);
}

.section {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.sectionInner {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.overviewLeft {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
    flex: 1;
}

.overviewRight {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    min-width: 340px;
    flex: 1;
    gap: 1rem;
}

.featureRow {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
    padding: .5rem 0;
}

.feature {
    display: flex;
    font-weight: 600;
    color: rgba(var(--text-color-rgb), 0.6);
    align-items: center;
    line-height: 1.5rem;
    gap: .3rem;
    white-space: nowrap;
}

.feature>svg {
    height: 20px;
    width: 20px;
}

.feature>svg>g>path {
    stroke: rgba(var(--text-color-rgb), 0.6);
}

.classesRow {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
}

.groups {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
    padding: 1rem 0;
    max-width: 90vw;
    scrollbar-width: 0;
}

.groups::-webkit-scrollbar{
    display: none;
}

.groupCard {
    display: inline-block;
    margin-right: 0.5rem;
    cursor: pointer;
}

.actionRow {
    width: 50%;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    padding: .5rem 0;
    flex-wrap: wrap;
}

.playerWrapper {
    display: flex;
    aspect-ratio: 16 / 9;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: fit-content;
}

.mobilePlayerWrapper{
    display: none;
}

.player {
    border-radius: 12px;
    overflow: hidden;
    max-width: 98vw;
}

.program{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow: hidden;
    height: 12rem;
    transition: .3s height ease-in;
    width: 100%;
}

.program.expanded{
    height: fit-content;
}

.programElem{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .5rem 0;
}

.programElemInner{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    font-weight: 600;
    width: 90%;
    height: 100%;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 12px;
}

.programButton{
    font-size: 1.05rem;
    gap: .3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;
}

.arrowUp{
    transform: rotate(-90deg);
    transition: all .2s ease-in-out;
}

.arrowDown{
    transform: rotate(90deg);
    transition: all .2s ease-in-out;
}

@media screen and (max-width: 1024px) {

    .sectionInner {
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }

    .overviewRight {
        min-width: 0;
        align-items: flex-start;
    }

    .mobilePlayerWrapper {
        display: flex;
        aspect-ratio: 16 / 9;
        border-radius: 12px;
        overflow: hidden;
        width: 100%;
        max-width: 98vw;
        min-width: 0px;
        height: fit-content;
    }

    .playerWrapper{
        display: none;
    }

    .teachers{
        max-width: none;
    }
    
}

@media screen and (max-width: 768px) {
    .actionRow {
        width: 70%;
    }
}

@media screen and (max-width: 540px) {
    .container {
        padding: 0;
    }

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 1rem;
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }

    .overviewRight {
        align-items: flex-start;
    }

    .actionRow {
        width: 100%;
    }

    .programElem{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .5rem 0;
    }

}
