.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    background-color: var(--background-secondary-color);
}

.section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.sectionInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    gap: 2rem;
}

.noCourses {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
    font-family: var(--primary-font);
    min-height: 200px;
}

@media screen and (max-width: 768px) {

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 2rem;
        flex-direction: column;
        gap: 2rem;
    }
}

@media screen and (max-width: 540px) {

    .sectionInner {
        padding: 1rem;
        gap: 2rem;
    }

    .material {
        width: 100%;
    }
}

