.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.tutor {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1rem;
    gap: 1rem;
    align-items: center;
    width: 100%;
}

.picture {
    display: flex;
    object-fit: cover;
    padding: 2px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    border: 1px solid var(--secondary);
}

.info {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.name {
    font-family: var(--primary-font);
    font-weight: 700;
    font-size: 0.875rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.details {
    font-size: 0.75rem;
    font-weight: 800;
    color: rgba(var(--text-color-rgb), 40%);
    font-family: var(--primary-font);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tutorButton {
    background: linear-gradient(45deg, rgba(76, 180, 255, 1) 0%, rgba(203, 102, 250, 1) 61%, rgba(250, 102, 118, 1) 100%) border-box;
    border: 2px solid transparent;
    transition: all .2s ease-in-out;
    color: white;
    box-shadow: none;
    font-size: 0.75rem;
    width: 'fit-content';
    max-width: 100px;
    padding: .5rem;
    font-weight: 600;
}

.divider {
    display: flex;
    height: 1px;
    width: 90%;
    background-color: rgba(var(--text-color-rgb), 12%);
}

@media (hover: hover) {
    .tutorButton:hover {
        color: #4CB4FF;
        background: linear-gradient(white, white) padding-box,
            linear-gradient(45deg, rgba(76, 180, 255, 1) 0%, rgba(203, 102, 250, 1) 61%, rgba(250, 102, 118, 1) 100%) border-box;
        border-radius: 50em;
        border: 2px solid noner;
        padding: .5rem;
        box-shadow: none;
    }
}