
.thumbnail {
    display: flex;
    height: 100%;
}

.container {
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    width: 100%;
    overflow: hidden;
    padding: 1rem;
    align-items: center;
}

.info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.infoInner {
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.title {
    font-weight: 700;
    font-size: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1.5rem; /* (line-height * numero di righe) + eventuali padding/margini */
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.dimensions {
    font-weight: 700;
    font-size: 0.75rem;
    color: rgba(var(--text-color-rgb), 64%);
}