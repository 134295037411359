.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  min-height: 196px;
  gap: .5rem;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
}

.message {
  font-size: 1rem;
}

.illustration {
  width: 128px;
}

.illustration>svg,
.illustration>img {
  width: 100%;
  height: 100%;
}
