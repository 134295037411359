.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem 3rem;
  background-color: var(--background-color);
  border-radius: 20px;
  /* border: 1px solid rgba(var(--text-color-rgb), 12%); */
  transition: all .2s ease;
  width: 100%;
}

.name {
  font-size: 1.625rem;
  font-weight: 800;
}

.description {
  font-size: 1.25rem;
  font-weight: 600;
  color: rgba(var(--text-color-rgb), 80%);
  text-align: center;
}

.paging {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.testNumber {
  display: flex;
  flex-direction: row;
  font-size: 1.125rem;
  font-weight: 600;
}


.totalTestsNumber {
  color: rgba(var(--text-color-rgb), 40%);
}