.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    min-height: calc(100dvh - 80px);
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding-top: 2rem;
}

.loader {
    min-height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.how {
    display: flex;
    flex-direction: column;
    padding: 0 3rem;
    gap: 1rem;
    max-width: 1440px;
}

.testDescription {
    color: rgba(3, 0, 60, 0.80);
}

.testCards {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 2rem 0;
}

.testCard {
    display: flex;
    flex-direction: column;
    flex-basis: 25%;
    border-radius: 1.25rem;
    padding: 2rem 1rem;
    gap: 1rem;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.cardImageWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 1rem;
}

.cardImage {
    display: flex;
    object-fit: contain;
    height: 100%;
    width: 50%;
}

.infos{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.infoCard {
    display: flex;
    flex-basis: 33%;
    border-radius: 1rem;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    flex-direction: column;
    padding: 2rem 1rem;
    gap: 1rem;
    color:  rgba(3, 0, 60, 0.8);

}

.arrowIcon {
    transform: rotate(180deg);
}

.topBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 3rem;
    padding-top: 0;
    align-items: center;
    width: 100%;
}

.progress {
    font-weight: 700;
    color: rgba(var(--text-color-rgb), 24%);
}

.restartButton {
    font-size: 1rem;
}

.questions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.result {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    padding: 2rem;
    max-width: 1440px;
}

.resultDesc {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    height: 100%;
    max-width: 50%;
}

.resultChart {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 1rem;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    padding: 1rem;
    gap: 1rem;
}

.resultChartInner>div>svg {
    display: flex;
    width: 100%;
}

.resultChartInner {
    display: flex;
    flex-direction: row;
}

.resultData {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    font-size: 1rem;
    flex: 1;
    justify-content: space-between;
    min-width: 200px;
    height: 100%;
}

.resultRow {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    white-space: nowrap;
    text-align: left;
    justify-content: space-between;
    border-radius: 1rem;
    background-color: var(--background-secondary-color);
    padding: 1rem;
    align-items: center;
}

.resultColor {
    display: flex;
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
    border-radius: 16px;
}

.resultRowInner {
    width: 100%;
}

.description {
    padding: 1rem;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 1rem;
    font-size: 0.875rem;
    font-family: var(--primary-font);
}

.descriptionTitle,
.consTitle,
.prosTitle {
    font-weight: 700;
    opacity: 0.6;
    font-size: 1.125rem;
    padding-bottom: 1rem;
    font-family: var(--secondary-font);
}

.consTitle {
    color: var(--secondary);
}

.prosTitle {
    color: var(--primary);
}

.prosCons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.pros,
.cons {
    flex-basis: 50%;
    padding: 1rem;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 1rem;
    font-size: 0.875rem;
    position: relative;
    overflow: hidden;
    font-family: var(--primary-font);
}

.pros {
    background: linear-gradient(180deg, rgba(22, 180, 250, 0.024) -31.4%, rgba(22, 180, 250, 0.12) 173.17%);
}

.cons {
    background: linear-gradient(180deg, rgba(109, 74, 255, 0.024) -31.4%, rgba(109, 74, 255, 0.12) 173.17%);
}

.proConsImage {
    position: absolute;
    top: -10%;
    right: 0;
    height: 120px;
    width: 120px;
    object-fit: contain;
    z-index: 1;
    opacity: 0.32;
}

.bear,
.bull {
    position: absolute;
    height: 250px;
    width: 250px;
    right: 1rem;
    top: 0;
    opacity: 0.2;
}

.prosChar,
.consChar {
    font-weight: 700;
    text-transform: uppercase;
}

.reset {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hint {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 1rem;
}

.hintContent {
    display: flex;
    flex-direction: column;
}

.hintDesc {
    max-width: 300px;
}

.hintImage {
    display: flex;
    object-fit: contain;
    height: 50px;
    width: 50px;
}

.hintTitle {
    font-size: 1rem;
    font-weight: 800;
}

.buttonContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 1rem 0;
}

.padded {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media screen and (max-width: 1045px) {
    .resultChartInner {
        flex-direction: column;
    }

    .resultDesc {
        max-width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .resultChart {
        flex-direction: column;
        gap: 1rem;
    }

    .resultData {
        width: 100%;
    }

    .content {
        align-items: flex-start;
        padding-top: 2rem;
    }

    .how {
        padding: 0 2rem;
    }

    .content {
        align-items: center;
    }

    .testCards {
        flex-wrap: wrap;
    }

    .testCard {
        flex-basis: 48%;
    }

    .topBar {
        padding: 0 1rem;
    }

    .hint {
        display: none;
    }

    .infos {
        flex-wrap: wrap;
    }

    .infoCard {
        display: flex;
        flex-basis: 48%;
    }
}



@media screen and (max-width: 540px) {

    .container {
        min-height: calc(100dvh - 60px);
    }

    .questions {
        width: 100%;
    }

    .content {
        padding: 0;
    }

    .anagraphic {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .prosCons {
        flex-direction: column;
    }

    .how {
        padding: 0;
    }

    .result {
        padding: 1rem;
    }

    .testCards {
        padding: 0;
    }

    .testCard {
        flex-basis: 100%;
        max-width: 95vw;
    }

    .topBar {
        padding-top: 1rem;
        padding-bottom: 2rem;
    }

    #cardsCarousel {
        display: flex !important;
        width: 100vw !important;
    }


    .cardSlide {
        padding: 1rem 0;
    }

    .padded {
        padding: 1rem;
    }

    .buttonContainer {
        padding: 1rem;
    }

    .infos {
        padding: 1rem;
    }


    .infoCard {
        flex-basis: 100%;
    }
}