.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: fit-content;
}

.header {
    display: flex;
    flex-direction: column;
    position: relative;
}

.background {
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.background > svg {
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: inset 0 0 0 1px rgba(var(--text-color-rgb), 12%);
}

.info {
    position: absolute;
    top: 45%;
    left: .5rem;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 1rem;
}

.title {
    font-family: var(--primary-font);
    font-size: 1.75rem;
    font-weight: 900;
}

.description {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    max-height: 6.5rem;
    height: 6.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 .25rem;
    line-height: 21px;
}

.actionButton {
    white-space: wrap;
    padding: 1rem 1rem;
}