.container {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--background-secondary-color);
    flex-wrap: nowrap;
}

.section {
    background-color: var(--background-secondary-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.sectionInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 750px;
    padding: 0 3rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.feed {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
}

.header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.courseName {
    font-size: clamp(1.2rem, 2vw, 2rem);
    display: flex;
    flex-direction: column;
}

.lastUpdate {
    font-size: 0.875rem;
    color: rgba(var(--text-color-rgb), 42%);
}

.storiesTrack {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    position: absolute;
    overflow-x: scroll;
    overflow-y: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 1rem;
}

.storiesContainer {
    display: flex;
    position: relative;
    width: 100%;
}

#storiesCarousel {
    display: flex;
    width: 100%;
    max-width: calc(100vw - 4rem);
    position: relative;
}

.arrowLeft {
    background-color: rgba(var(--text-color-rgb), 28%);
    color: white;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 45%;
    left: -7%;
    padding: .5rem;
    transform: translateY(-50%) rotate(-90deg);
    z-index: 1;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.arrowRight {
    background-color: rgba(var(--text-color-rgb), 28%);
    color: white;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 45%;
    right: -7%;
    padding: .5rem;
    transform: translateY(-50%) rotate(90deg);
    z-index: 1;
    transition: all .2s ease-in-out;
    cursor: pointer;
}


.stories {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1;
    width: 100%;
    gap: 2rem;
    overflow: scroll;
    min-height: 150px;
}

.story {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    min-width: 128px;
    padding-top: 1rem;
    container: slide / inline-size;
}

.storyName {
    font-size: 0.875rem;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    word-break: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

.storyThumbnail {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    border-radius: 100px;
    padding: 2px;
    border: 2px solid #D6D6D6;
    object-fit: cover;
}

.storyThumbnail.toStart {
    border: 2px solid var(--primary);
}

.storyThumbnail.live {
    animation: onair_wave 2s ease-out infinite;
    border: 2px solid #DA3030;
}

.toolbarContainer {
    display: flex;
    position: sticky;
    top: 80px;
    flex-direction: column;
    width: 300px;
    min-width: 300px;
    height: 100%;
    max-height: 100vh;
    min-height: calc(100vh - 72px);
    background-color: var(--background-color);
    overflow-y: scroll;
    scrollbar-width: 0px;
}

.toolbarContainer::-webkit-scrollbar {
    display: none;
}

.toolbar {
    padding: 1.5rem 0;
    display: flex;
    position: relative;
    /* top: 80px; */
    flex-direction: column;
    padding-bottom: 100px;
}

.course {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    gap: 1rem;
}

.divider {
    display: flex;
    width: 100%;
    margin: 1rem 0;
    height: 1px;
    background-color: rgba(var(--text-color-rgb), 12%);
}

.nextLessons,
.lessons,
.materials {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: .5rem;
    padding: 0 1rem;
}

.nextLesson {
    display: flex;
    flex: 1;
    background-color: rgba(var(--text-color-rgb), 4%);
    padding: 2px;
    flex-direction: row;
    border-radius: 8px;
}

.nextLessonDate {
    height: 44px;
    width: 44px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: var(--background-color);
    align-items: center;
    justify-content: center;
}

.nextLessonMonth {
    display: flex;
    color: var(--primary);
    font-size: 0.625rem;
    font-weight: 800;
}

.nextLessonDay {
    color: rgba(var(--text-color-rgb), 88%);
    font-size: 1.125rem;
    font-weight: 800;
    line-height: 100%;
}

.nextLessonInfo {
    padding: 0 .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.nextLessonWeekDay {
    font-size: 0.75rem;
    color: rgba(var(--text-color-rgb), 48%);
    font-weight: 700;
}

.nextLessonHours {
    font-weight: 700;
    color: rgba(var(--text-color-rgb), 88%);
    font-size: 1rem;
}

.lesson {
    display: flex;
    flex: 1;
    flex-direction: row;
    border-radius: 8px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem;
    cursor: pointer;
    font-family: var(--primary-font);
}

.chevronDown {
    width: 16px;
    height: 16px;
    object-fit: contain;
    transform: rotate(180deg);
}

.chevronUp {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.tutor {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: .5rem;
    padding: 0 1rem;
    align-items: center;
    flex-wrap: nowrap;
}

.tutorInfo {
    padding-right: .5rem;
}

.tutorButton {
    background: linear-gradient(45deg, rgba(76, 180, 255, 1) 0%, rgba(203, 102, 250, 1) 61%, rgba(250, 102, 118, 1) 100%) border-box;
    border: 2px solid transparent;
    transition: all .2s ease-in-out;
    color: white;
    box-shadow: none;
}

.unavailable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 1rem;
    align-items: center;
    height: 60dvh;
}

@media (hover: hover) {
    .tutorButton:hover {
        color: #4CB4FF;
        background: linear-gradient(white, white) padding-box,
            linear-gradient(45deg, rgba(76, 180, 255, 1) 0%, rgba(203, 102, 250, 1) 61%, rgba(250, 102, 118, 1) 100%) border-box;
        border-radius: 50em;
        border: 2px solid noner;
        padding: .5rem;
        box-shadow: none;
    }

    .lesson:hover {
        border: 1px solid rgba(var(--primary-rgb), 100%);
    }

    .arrowLeft:hover {
        background-color: rgba(var(--text-color-rgb), 88%);
        transition: all .2s ease-in-out;
    }

    .arrowRight:hover {
        background-color: rgba(var(--text-color-rgb), 88%);
        transition: all .2s ease-in-out;
    }
}

@media screen and (max-width: 1024px) {

    .toolbarContainer {
        display: none;
    }

}

@media screen and (max-width: 768px) {

    .section {
        padding: 2rem 0;
    }

    .sectionInner {
        padding: 0 2rem;
    }

    .stories {
        gap: 1rem;
        min-height: 100px; 
    }

    #storiesCarousel {
        display: flex;
        width: 100%;
        max-width: calc(100vw - 2rem);
    }
}

@media screen and (max-width: 540px) {

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 1rem;
    }

    .story {
        min-width: 90px;
    }

    .storyThumbnail {
        min-width: 90px;
        min-height: 90px;
        width: 90px;
        height: 90px;
    }

    .arrowLeft {
        left: 0%;
    }

    .arrowRight {
        right: 0%;
    }

}

@keyframes onair_wave {
    0% {
        box-shadow: 0 0 0 0 #DA3030;

    }

    100% {
        box-shadow: 0 0 0 8px rgba(218, 48, 48, 0);
    }
}

;