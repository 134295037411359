.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    cursor: pointer;
}

.container.expanded {
    cursor: default;
}


.info {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.avatar {
    width: 72px;
    height: 72px;
    border: 2px solid;
    padding: 4px;
    border-radius: 50%;
}

.infoInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .5rem;
}

.name {
    font-size: 1rem;
    font-weight: 700;
    color: var(--text-color);
}

.name.expanded{
    font-size: 1.25rem;
}

.qualification {
    font-size: 0.875rem;
    font-weight: 500;
    color: rgba(var(--text-color-rgb), 60%);
}

.qualification.expanded {
    font-size: 0.875rem;
}

.tags {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    flex-wrap: wrap;
}

.description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 4.2em; /* (line-height * numero di righe) + eventuali padding/margini */
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.75rem;
}

.description.expanded {
    display: flex;
    overflow: visible;
    max-height: fit-content;
    font-size: 1rem;
}

.closeCard {
    display: flex;
    position: absolute;
    height: 24px;
    width: 24px;
    top: .5rem;
    right: .5rem;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 40px;
    background-color: transparent;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.closeCard > svg > path {
    fill: rgba(var(--text-color-rgb), 64%);
}