.wrapper {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  border-radius: 20px;
  border: 1.5px solid rgba(var(--text-color-rgb), 10%);
  background-color: rgba(42, 42, 42, 0.04);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  --accent-color: var(--primary)
}


.selected {
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  border-radius: 20px;
  background-color: var(--accent-color);
  opacity: 0;
  transition: all .2s ease;
}


.selected.visible {
  opacity: 1;
}
