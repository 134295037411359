.container{
    display: flex;
    position: relative;
    background-color: #E1E6EE;
    width: 100%;
}

.percent {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0px 50px 50px 0 ;
}