
.container {
    display: flex;
    height: 48px;
    width: 48px;
    border-radius: 24px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.badge {
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: var(--secondary);
    color: white;
    font-weight: 700;
    font-size: 0.75rem;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid white;
    pointer-events: none;
}

.cartIcon{
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    pointer-events: none;
}

.cartFillIcon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    pointer-events: none;
}

.cartFillIcon > path {
    fill: black !important;
}

.dropdown {
    display: flex;
    position: absolute;
    width: 390px;
    bottom: -102px;
    right: 20px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 0px 1px 1px rgba(var(--text-color-rgb), 12%);
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.dropdown:before {
	content: '';
	position: absolute;
	top: -15px;
	right: 35px;
    transform: rotate(180deg);
	width: 0;
	height: 0;
	border: 16px solid transparent;
	border-top-color: white;
	border-bottom: 0;
	margin-left: -10px;
	margin-bottom: -10px;
    filter: drop-shadow(0px 1px 1px rgba(var(--text-color-rgb), 12%));
}

.empty {
    height: 390px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 3rem;
    text-align: center;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    padding: 1rem;
    align-items: center;
}


.products {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.3rem;
}

.productNumber {
    font-size: 1rem;
    font-weight: 700;
    color: rgba(var(--text-color-rgb), 56%)
}

.product {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    justify-content: space-between;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    gap: 1rem;
    align-items: center;
}

.productThumbnail {
    width: 56px;
    height: 32px;
    border-radius: 3px;
    object-fit: cover;
}

.productInfo{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: .5rem;
}

.productName {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    font-weight: 800;
    font-size: 1rem;
}

.deleteButton {
    background-color: transparent;
    border: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
    gap: .2rem;
    padding: 0;
    cursor: pointer;
    color: var(--secondary);
}

.productPrice {
    font-weight: 600;
    color: rgba(var(--text-color-rgb), 76%);
    font-size: 0.875rem;
    white-space: nowrap;
}

.total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1rem .25rem 1rem;
    font-weight: 800;
    font-size: 1rem;
    color: var(--text-color);
}

.discounted {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 1rem;
    padding-top: .25rem;
    font-weight: 700;
}

.actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 1rem;
}

.loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(var(--background-color-rgb), 50%);
}