.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
}

.section {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.sectionInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.banner {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.bannerLeft {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0rem;
    padding: 1rem;
    padding-right: 2rem;
}

.bannerRight {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
}

.bannerDescription {
    white-space: pre-line;
}

.playerWrapper {
    display: flex;
    aspect-ratio: 16 / 9;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: fit-content;
}

.player {
    border-radius: 12px;
    overflow: hidden;
    max-width: 98vw;
    background-color: black;
}

.bannerImage {
    display: flex;
    width: 100%;
    border-radius: 1rem !important;
    overflow: hidden !important;
}

.bannerImage>img {
    display: flex;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16/9;
}

.onboarding {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem
}

.onboardingLeft,
.onboardingRight {
    width: 50%;
    flex: 1;
    flex-shrink: 0;
}

.macros {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.macro {
    display: flex;
    flex-basis: 33%;
    width: 33%;
    padding: 1rem;
}

.videos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.video {
    display: flex;
    flex-basis: 33%;
    padding: .5rem;
}

.alert {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: rgba(var(--sf-red-rgb), 0.1);
    border: 1px solid  rgba(var(--sf-red-rgb), 0.5);
    border-radius: 12px;
    gap: .5rem
}

.alert.success {
    background-color: rgba(var(--sf-green-rgb), 0.1);
    border: 1px solid  rgba(var(--sf-green-rgb), 0.5);
}

.alertDescription {
    white-space: pre-line;
    font-size: .75rem;
    opacity: 0.85;
}

@media screen and (max-width: 1024px) {

    .sectionInner {
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }

}

@media screen and (max-width: 768px) {
    .onboarding {
        flex-direction: column;
    }

    .onboardingLeft,
    .onboardingRight {
        width: 100%;
    }

    .onboardingRight{
        min-height: 380px;
    }

    .banner {
        flex-direction: column-reverse;
    }

    .bannerLeft,
    .bannerRight {
        width: 100%;
    }

    .bannerLeft {
        padding: 1rem 0;
    }

    .macro {
        flex-basis: 50%;
        width: 50%;
    }

    .video {
        display: flex;
        flex-basis: 50%;
    }
}

@media screen and (max-width: 540px) {
    .container {
        padding: 0;
    }

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 1rem;
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }

    .macros {
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 1.5rem;
    }

    .macro {
        flex-basis: 100%;
        width: 100%;
    }

    .video {
        display: flex;
        flex-basis: 100%;
    }
}