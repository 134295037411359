.container{
    display: flex;
    flex-direction: row;
    flex: 1;
    box-sizing: border-box;
}

.columnLeft{
    display: flex;
    width: 50%;
    flex-direction: column;
    padding: 2rem 0;
    justify-content: center;
    align-items: center;    
}

.columnRight{
    display: flex;
    padding: 2rem 2rem;
    width: 50%;
}

.logoContainer{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding: 1rem 3rem;
    max-width: 490px;
}

.logo{
    width: 10rem;
    height: auto;
    object-fit: contain;
    cursor: pointer;
}

.innerLeft{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0 3rem;
    max-width: 490px;
}

.title, .body{
    display: flex;
    width: 100%;
    text-align: left;
}

.formContainer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 1rem 0 0.5rem 0 ;
}

.resetPassword{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.textDivider{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 1rem;
    color: rgba(var(--text-color-rgb), 40%);
    padding: 1rem 0;
}

.divider{
    display: flex;
    width: 100%;
    background-color: rgba(var(--text-color-rgb), 12%);
    height: 1px;
}

.socialLogin{
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: space-around;
}

.googleLogo{
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.googleSignIn{
    color: var(--text-color);
    background: rgb(242,242,242);
    font-family: var(--primary-font);
    font-weight: 500;
    border: none;
    border-radius: 2rem;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
    width: 100%;
    justify-content: center;
}

.appleLogo{
    width: 20px;
    height: 20px;
    object-fit: contain;
    fill: white;
}

.appleLogo > svg {
    fill: white;
}

.appleSignIn{
    background: black;
    color: white;
    font-family: var(--primary-font);
    font-weight: 500;
    border: none;
    border-radius: 2rem;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
    width: 100%;
    justify-content: center;
}

.spacer{
    flex: 1;
}

.registerContainer{
    display: flex;
    font-size: .9rem;
    align-items: center;
}

.error{
    color: red;
    display: flex;
    width: 100%;
    text-align: left;
    font-weight: 600;
}

.innerRight{
    background: linear-gradient(0deg, #dae2fc 0%, #daf4fe 100%);
    width: 100%;
    background-size: cover;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    gap: 1rem;
    border-radius: 3rem;
}

.redCircle{
    width: 82px;
    height: 82px;
    background: linear-gradient(90deg, rgba(var(--secondary-rgb),0.4) 0%, rgba(var(--secondary-rgb),0) 100%);
    position: absolute;
    top: -41px;
    left: 50px;
    border-radius: 50px;
}

.rightTitle{
    text-align: center;
    width: 100%;
    font-size: 1.6rem;
    padding: 0 5rem;
}

.rightBody{
    text-align: center;
}

.mockup{
    width: 60%;
    height: 200px;
    background-color: white;
    -webkit-box-shadow: 0px 5px 25px 2px rgba(0,0,0,0.3); 
    box-shadow: 0px 5px 25px 2px rgba(0,0,0,0.1);
    border-radius: 1rem;
    margin: 2rem 0;
}

.slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.screenshot {
    display: flex;
    object-fit: contain;
    height: 50%;
    width: 70%;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    
    .columnRight{
        display: none;
    }

    .columnLeft{
        padding: 0;
        width: 100%;
        min-height: 100vh;
    }

    .logoContainer{
        max-width: 100%;
        padding: 1.5rem 1rem;
        border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    }
    
    .logo{
        width: 9rem;
    }

    .innerLeft{
        justify-content: flex-start;
        padding: 1rem 1rem 2rem 1rem;
    }

    .spacer{
        flex: 0;
    }

}

@media screen and (max-width: 540px) {
    .socialLogin{
        flex-direction: column;
    }
}