.container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 260px;
    height: 100%;
    overflow: visible;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
}

.gradientLeft{
    position: absolute;
    width: 100%;
    top: 0;
    height: 50px;
    background: linear-gradient(180deg, var(--background-color) 0%, rgba(255,255,255,0) 100%);
    z-index: 2;
    display: none;
}

.gradientRight{
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 50px;
    background: linear-gradient(0deg, var(--background-color) 0%, rgba(255,255,255,0) 100%);
    z-index: 2;
    display: none;
}

.track {
    display: flex;
    flex-direction: column;
    position: relative;
    left: 0;
    align-items: center;
    width: 100%;
    height: fit-content;
    z-index: 1;
    justify-content: center;
}

.track.animated {
    position: absolute;
    justify-content: flex-start;
    animation: slide 30s linear infinite;
}

.track.animatedReverse {
    position: absolute;
    justify-content: flex-start;
    animation: slideReverse 30s linear infinite;
}

.card {
    display: flex;
    width: 100%;
    max-height: 160px;
    aspect-ratio: 16/9;
    border-radius: 12px;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    /* box-shadow: 0px 0px 50px 5px rgba(var(--text-color-rgb), 12%); */
}

.card.animated {
    /* width: 240px;
    height: 160px; */
}

.card > img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 16/9;
}


/* @media(hover: hover){
    .track:hover{
        animation-play-state: paused;
    }
} */

@keyframes slide {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-33.33%);
    }
}

@keyframes slideReverse {
    0% {
        transform: translateY(-33.33%);
    }

    100% {
        transform: translateY(0);
    }
}