.container {
  width: 100%;
  height: 2px;
  background-color: var(--background-secondary-color);
  position: relative;
}

.bar {
  position: absolute;
  top: 0;
  border: 1px solid var(--primary);
  transition: .4s all ease;
}
