.container {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
}

.gradientLeft {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6vw;
    background: linear-gradient(90deg, var(--background-color) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 2;
}

.gradientRight {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 6vw;
    background: linear-gradient(270deg, var(--background-color) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 2;
}

.track {
    display: flex;
    position: relative;
    left: 0;
    align-items: center;
    height: 450px;
    min-width: 100%;
    width: fit-content;
    height: 100%;
    z-index: 1;
    justify-content: center;
}

.track.animated {
    position: absolute;
    justify-content: flex-start;
    animation: slide linear infinite;
}

.card {
    display: flex;
    width: 500px;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.card.animated {
    min-width: min(500px, 90vw);
    width: min(500px, 90vw);
}

.track.stopped {
    animation-play-state: paused;
}

@media(hover: hover) {
    .track:hover {
        animation-play-state: paused;
        overflow-x: scroll;
    }
}

@media screen and (max-width: 540px) {
    .gradientLeft, .gradientRight {
        width: 0;
    }
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-33.33%);
    }
}