.container{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    overflow: hidden;
    width: 100%;
}

.row{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.inner{
    display: flex;
    padding: 1rem;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
}

.column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.title{
    color: rgba(var(--text-color-rgb), 80%);
    font-weight: 600;
    width: 100%;
    justify-content: flex-start;
}

.skills{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: .9rem;
    font-family: var(--primary-font);
    font-weight: 700;
    width: 100%;
    align-items: flex-end;
}

.percent{
    font-family: var(--primary-font);
    font-weight: 800;
    font-size: 1rem;
}

.image{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: cover;
}