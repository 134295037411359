.material {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 8px;
  padding: .7rem;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.info {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  position: relative;
}

.name {
  font-size: .875rem;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  font-family: var(--primary-font);
}

.size {
  font-weight: 500;
  font-size: 0.75rem;
  color: rgba(var(--text-color-rgb), 48%);
}

.download {
  border: 1.33px solid rgba(var(--primary-rgb), 32%);
  background-color: rgba(var(--primary-rgb), 4%);
  border-radius: 8px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  border: 1.33px solid rgba(var(--secondary-rgb), 32%);
  background-color: rgba(var(--secondary-rgb), 4%);
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
  color: var(--secondary);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
