.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: 2rem;
}

.text {
  font-size: 1.125rem;
  /* font-weight: 600; */
}

.type {
  font-size: 1.125rem;
  /* font-weight: 600; */
}

.answer,
.answerSolution {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  border-radius: 12px;
  border: 1px solid rgba(var(--text-color-rgb), 10%);
  padding: 1.5rem 2rem;
  width: 100%;
  transition: all .2s ease;
  cursor: pointer;
  /* font-weight: 600; */
}

.answerSolution {
  cursor: default;
}

.answerSolution.selected {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}

.answer.selected {
  border: 1.5px solid var(--primary);
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}

.answerSolution.correct {
  background-color: rgba(var(--sf-light-green-rgb), 6%);
}

.answerSolution.wrong {
  background-color: rgba(var(--sf-red-rgb), 6%);
  border: 1.5px solid rgba(var(--sf-red-rgb), 100%);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.numberOfErrors {
  font-size: 1.125rem;
  font-weight: 600;
}

@media(hover:hover) {
  .answer:hover {
    border: 1.5px solid var(--primary);
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 540px) {

  .answer,
  .answerSolution {
    padding: 1rem;
    flex-direction: column;
    gap: .5rem;
    text-align: start;
  }
}
