.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.header {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.steps {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: .5rem;
    max-height: 300px;
    overflow: scroll;
    padding: .5rem 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.steps::-webkit-scrollbar {
    display: none;
}

.step {
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 14px;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    transition: .2s all ease-in-out;
    align-items: center;
}

.step.selected {
    border: 1px solid var(--primary);
}

.innerSteps {
    font-weight: 600;
    font-size: .875rem;
}

.check {
    width: 32px;
    height: 32px;
    min-height: 32px;
    min-width: 32px;
    background-color: #E3E3E3;
    border-radius: 32px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check.completed {
    background-color: var(--secondary);
}

.check>svg {
    width: 16px;
    height: 16px;
    min-height: 16px;
    min-height: 16px;
    transform: translate(.25px, .5px);
}

.stepTitle {
    font-weight: 600;
    color: var(--text-color);
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
}

.arrow {
    width: 32px;
    height: 32px;
    transform: rotate(180deg);
    color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (hover:hover) {
    .step:hover {
        background-color: rgba(var(--primary-rgb), 12%);
        transition: .2s all ease-in-out;
    }
}