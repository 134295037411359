.icon {
    position: relative;
    height: 48px;
    width: 48px;
    object-fit: contain;
    margin: 1rem;
}

.icon > svg {
    display: flex;
    width: 100%;
    height: 100%;
}


.gradient {
    position: absolute;
    width: 300px;
    height: 300px;
    top: -60px;
    right: -50px;
}