.comingSoon {
    filter: grayscale(0.8);
}

.header {
    display: flex;
    flex-direction: row;
    padding: .7rem .9rem;
    background-color: rgba(var(--text-color-rgb), 4%);
    gap: .5rem;
    align-items: center;
}

.courseImage {
    width: 70px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
}

.date {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
}

.day {
    font-weight: 700;
    font-size: 0.75rem;
    color: rgba(var(--text-color-rgb), 48%);
}

.hours {
    font-size: 1rem;
    font-weight: 700;
    color: rgba(var(--text-color-rgb), 88%);
}

.course {
    display: flex;
    flex-direction: column;
    padding: .7rem .9rem;
    gap: .5rem;
    flex: 1;
}

.courseTitle {
    font-size: 1rem;
    font-weight: 700;
    color: rgba(var(--text-color-rgb), 88%);
    flex: 1;
}

.badges{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .5rem;
}

.lessonButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    font-size: 0.875rem;
    width: 100%;
    background: transparent;
    padding: 1rem;
    border-radius: 0;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
}

.lessonLiveButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    font-size: 0.875rem;
    width: 100%;
    padding: 1rem;
    border-radius: 0;
}

.arrowIcon {
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
    transform: rotate(180deg);
}

@media (hover: hover) {
    .lessonLiveButton:hover {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        width: 100%;
        padding: 1rem;
        border-radius: 0;
        transition: .2s all ease-in;
        background: #16B4FA;
        color: white;
    }
}