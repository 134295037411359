.actionButton {
    --button-accent-color: var(--primary);
    --button-hover-color: var(--background-color);
    background-color: var(--button-accent-color);
    color: white;
    font-family: var(--secondary-font);
    font-size: .8rem;
    font-weight: 700;
    border: none;
    border-radius: 10rem;
    padding: .6rem 4rem;
    width: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    min-width: fit-content;
}

.gradient {
    --button-gradient-start: #16C0FB;
    --button-gradient-end: #16A7F8;
    background: linear-gradient(to bottom, var(--button-gradient-start) 0%, var(--button-gradient-end) 300%) border-box;
    border: 2px solid transparent;
    transition: all .2s ease-in-out;
    color: white;
    box-shadow: none;
    border-radius: 10rem;
    padding: .6rem 4rem;
    font-size: .8rem;
    font-weight: 700;
    width: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    min-width: fit-content;
}

.gradient:disabled {
    cursor: not-allowed;
    background: #EDEDED;
    color: white;
}

.gradient.inverse {
    color: #4CB4FF;
    background: linear-gradient(var(--background-color), var(--background-color)) padding-box,
        linear-gradient(to bottom, var(--button-gradient-start) 0%, var(--button-gradient-end) 300%) border-box;
    border-radius: 10rem;
    border: 2px solid transparent;
}

.gradient.inverse:disabled {
    background: linear-gradient(var(--background-color), var(--background-color)) padding-box,
        linear-gradient(to bottom, #EDEDED 0%, #EDEDED 300%) border-box;
    border-radius: 10rem;
    border: 2px solid transparent;
}


.textButton {
    color: var(--button-accent-color);
    font-family: var(--primary-font);
    font-weight: 600;
    border: none;
    background: unset;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 0.01rem;
    font-size: .75rem;
}

.textButton:disabled {
    color: gray;
    cursor: not-allowed;
}

.socialButton {
    border: 2px solid rgba(var(--background-color-rgb), 16%);
    background-color: var(--text-color);
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialButton>svg {
    color: white;
    font-size: 1.2rem;
}

.fullWidth {
    width: 100%;
    padding: .6rem 0;
}

.actionButton:disabled {
    cursor: not-allowed;
    background-color: rgba(var(--text-color-rgb), 20%);
}

.actionButton.inverse {
    color: var(--button-accent-color);
    background-color: transparent;
    box-shadow: inset 0px 0px 0px 2px var(--button-accent-color);
    transition: .2s ease-in-out all;
}

.actionButton.inverse:disabled {
    cursor: not-allowed;
    background-color: white;
    color: rgba(var(--text-color-rgb), 20%);
    box-shadow: inset 0px 0px 0px 2px rgba(var(--text-color-rgb), 20%);
}

.actionButton.inverse>svg>path {
    fill: var(--button-accent-color);
}

.successIcon {
    width: 18px;
    height: 18px;
}

.successIcon>path {
    fill: none !important;
}

.errorIcon {
    width: 18px;
    height: 18px;
}

.errorIcon path {
    fill: currentColor !important;
}

@media (hover: hover) {

    .actionButton:disabled:hover {
        cursor: not-allowed;
        background-color: rgba(var(--text-color-rgb), 20%);
        box-shadow: inset 0px 0px 0px 0px var(--button-accent-color);
        color: white;
    }

    .actionButton:disabled:hover>svg>path {
        fill: white;
        transition: .2s ease-in-out all;
    }


    .actionButton:hover {
        color: var(--button-accent-color);
        background-color: transparent;
        box-shadow: inset 0px 0px 0px 2px var(--button-accent-color);
        transition: .2s ease-in-out all;
    }

    .actionButton:hover>svg>path {
        fill: var(--button-accent-color);
        transition: .2s ease-in-out all;
    }

    .actionButton:hover>svg>g>path {
        fill: var(--button-accent-color);
        transition: .2s ease-in-out all;
    }

    .actionButton.inverse:hover {
        background-color: var(--button-accent-color);
        color: var(--button-hover-color);
        transition: .2s ease-in-out all;
    }

    .actionButton.inverse:disabled:hover {
        background-color: white;
        color: rgba(var(--text-color-rgb), 20%);
        box-shadow: inset 0px 0px 0px 2px rgba(var(--text-color-rgb), 20%);
    }

    .actionButton.inverse:hover>svg>path {
        fill: var(--button-hover-color);
        transition: .2s ease-in-out all;
    }

    .actionButton.inverse:disabled:hover>svg>path {
        fill: rgba(var(--text-color-rgb), 20%);
    }

    .actionButton.inverse:disabled:hover>svg>g>path {
        fill: rgba(var(--text-color-rgb), 20%);
    }

    .actionButton.inverse:hover>svg>g>path {
        fill: var(--button-hover-color);
        transition: .2s ease-in-out all;
    }

    .socialButton:hover {
        background-color: white;
        transition: .2s ease-in-out all;
    }

    .socialButton:hover>svg {
        color: var(--text-color);
        transition: .2s ease-in-out all;
    }

    .gradient:hover {
        color: var(--button-accent-color);
        background: linear-gradient(var(--background-color), var(--background-color)) padding-box,
            linear-gradient(to bottom, var(--button-gradient-start) 0%, var(--button-gradient-end) 300%) border-box;
        border-radius: 10rem;
        border: 2px solid transparent;
    }

    .gradient:hover>svg>path {
        background: transparent;
        transition: all .2s ease-in-out;
        fill: var(--button-accent-color);
    }

    .gradient.inverse:hover {
        background: linear-gradient(to bottom, var(--button-gradient-start) 0%, var(--button-gradient-end) 300%) border-box;
        border: 2px solid transparent;
        transition: all .2s ease-in-out;
        color: white;
        box-shadow: none;
    }

    .gradient.inverse:hover>svg>path {
        fill: var(--background-color);
        transition: .2s ease-in-out all;
    }

    .gradient:disabled:hover {
        background: linear-gradient(var(--background-color), var(--background-color)) padding-box,
            linear-gradient(to bottom, #EDEDED 0%, #EDEDED 300%) border-box;
        border-radius: 10rem;
        border: 2px solid transparent;
        color: gray;
    }

    .gradient:disabled:hover>svg>path {
        fill: white;
    }
}