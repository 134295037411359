.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.timeline {
    width: 10%;
    min-width: 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(var(--text-color-rgb), 64%);
    font-weight: 600;
}

.dashed {
    width: 2px;
    background-image: linear-gradient(rgba(var(--text-color-rgb),12%) 33%, rgba(255,255,255,0) 0%);
    background-position: right;
    background-size: 2px 16px;
    background-repeat: repeat-y;
}

.accordions {
    width: 90%;
    display: flex;
}

.accordion {
    display: flex;
    width: 100%;
    padding: .5rem 0;
}


@media screen and (max-width: 540px){
    .timeline {
        width: 12%;
    }

    .accordions {
        width: 88%;
    }
}