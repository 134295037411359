.container {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-bottom: 3rem;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--background-secondary-color);
}

.section {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.sectionInner {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    /* flex-wrap: wrap; */
    /* gap: 2rem; */
}

.back {
    padding-top: 0;
}

.interest {
    padding-top: 0;
    padding-bottom: 1rem;
    justify-content: flex-end;
}

.main {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.macroLeft {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
}

.macroRight {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    gap: .5rem;
    padding: 0 1rem;
    min-width: 360px;
}

.description {
    font-family: var(--primary-font);
}

.playerWrapper,
.playerWrapper>img {
    display: flex;
    aspect-ratio: 16 / 9;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: fit-content;
}

.mobilePlayerWrapper {
    aspect-ratio: 16 / 9;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    display: none;
}

.player {
    border-radius: 12px;
    overflow: hidden;
    max-width: 98vw;
}

.description {
    padding: .5rem 0;
}

.careerButton {
    background: none;
    border: none;
    color: var(--tertiary);
    cursor: pointer;
    font-weight: 800;
    font-size: 1rem;
    font-family: var(--primary-font);
    display: flex;
    justify-content: flex-start;
    padding: .5rem 0;
}

.macroContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.viewDetail {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .main {
        flex-direction: column-reverse;
        gap: 1rem;
        min-width: 0;
    }

    .macroLeft {
        width: 100%;
    }

    .macroRight {
        width: 100%;
        padding: 0;
        min-width: 0;
    }
}

@media screen and (max-width: 540px) {

    .sectionInner {
        padding: 1rem;
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }

    .back {
        padding-top: 0;
        padding-bottom: 1rem;
    }

    .back>button {
        padding: 0 !important;
    }
}
