.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.row {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.column>img {
    display: flex;
    object-fit: contain;
    width: 100%;
    border-radius: 1rem;
}

.playerWrapper {
    display: flex;
    border-radius: 1rem;
    overflow: hidden;
    width: 100%;
}

ul {
    padding-left: 1.5rem;
}

.text {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .row {
        flex-wrap: wrap;
        justify-content: center;
    }

    .column {
        flex-basis: 30%;
        flex-grow: 1;
    }
}

@media screen and (max-width: 540px) {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    .row {
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 0;
    }

    .column {
        width: 100% !important;
        flex: 1;
    }
}
