.container {
  padding: .2rem .5rem;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  background-color: var(--background-color);
  transition: .2s all ease;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}

.container.false {
  color: var(--sf-red);
  border: 1.5px solid rgba(var(--sf-red-rgb), 10%);
}

.container.false.checked {
  color: white;
  background-color: var(--sf-red);
}


.container.true {
  color: var(--sf-light-green);
  border: 1.5px solid rgba(var(--sf-light-green-rgb), 10%);
}

.container.true.checked {
  color: white;
  background-color: var(--sf-light-green);
}

.container.true.correct {
  border: 2px solid var(--sf-green);
}

.container.false.correct {
  border: 2px solid var(--sf-red);
}


@media(hover:hover) {
  .container.false:hover {
    border: 1.5px solid var(--sf-red);
  }

  .container.true:hover {
    border: 1.5px solid var(--sf-green);
  }
}
