.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #171717;
    border-radius: 1.5rem;
    padding: 1rem;
}

.main {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    position: relative;
    height: 70vh;
}

.main.fullscreen {
    height: none;
    flex: 1;
}

.whiteboardName {
    position: absolute;
    font-family: var(--primary-font);
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--background-color);
    padding: .5rem 1rem;
    top: .15rem;
    right: .15rem;
    background-color: rgba(0, 0, 0, 64%);
    border-radius: 10px;
    z-index: 9;
}

.spotlighted {
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: black;
    position: relative;
}

.spotlighted>video {
    display: flex;
    width: 100%;
    border-radius: 12px;
}

.spotlightedName {
    position: absolute;
    font-family: var(--primary-font);
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--background-color);
    padding: .5rem 1rem;
    bottom: .15rem;
    left: .15rem;
    background-color: rgba(0,0,0,64%);
    border-radius: 8px;
}

.overlay {
    display: flex;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba(0,0,0,50%);
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    padding: 1rem;
    border-radius: 1rem;
}

.overlay > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.participantsSplide {
    display: flex !important;
    flex-direction: row;
    max-width: calc(100vw - 8rem) !important;
}

.participants {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    overflow: hidden;
    transition: .1s all ease-in-out;
    margin-bottom: 1rem;
    gap: 1rem;
    max-height: 120px;
}

.participants.close {
    max-height: 0;
    overflow-y: hidden;
    transition: .1s all ease-in-out;
    margin-bottom: 0;
}

.participantsTrack {
    display: flex !important;
    position: relative !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
}

.participantsTrack > ul {
    display: flex !important;
    max-width: 100% !important;
}

.participantsTrack::-webkit-scrollbar {
    display: none;
}

.arrowLeft {
    background-color: rgba(var(--text-color-rgb), 28%);
    color: white;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 45%;
    left: 0;
    padding: .5rem;
    transform: translateY(-50%) rotate(-90deg);
    z-index: 1;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.arrowRight {
    background-color: rgba(var(--text-color-rgb), 28%);
    color: white;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 45%;
    right: 0;
    padding: .5rem;
    transform: translateY(-50%) rotate(90deg);
    z-index: 1;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

video::-webkit-media-controls-panel {
    display: none !important;
    opacity: 1 !important;}

video::-webkit-media-controls {
    display: none !important;
}

.pinBadge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    background-color: var(--sf-yellow);
    position: absolute;
    top: .5rem;
    left: .5rem;
    padding: .25rem .5rem;
    border-radius: 8px;
    font-size: .75rem;
    font-weight: 700;
    color: white;
}

.pinBadge > svg {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

.meetingLeft {
    display: flex;
    flex-direction: column;
    color: var(--background-color);
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

@media screen and (max-width: 768px) {
    
    .participantsSplide {
        max-width: calc(100vw - 4rem) !important;
    }
    
}

@media screen and (max-width: 540px) {
    
    .main {
        height: 50vh;
    }

}