.container {
    display: flex;
    background-color: rgba(var(--background-color-rgb), 4%);
    width: 0;
    min-width: 0;
    height: 100%;
    transition: .1s all ease-in-out;
    overflow: hidden;
    flex-direction: column;
    border-radius: 12px;
    gap: .5rem;
}

.container.open {
    width: 35%;
    min-width: 300px;
    padding: 1rem;
    margin-left: .5rem;
    transition: .1s all ease-in-out;
}

.messages {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: scroll;
    display: flex;
    flex: 1;
    flex-shrink: 0;
    flex-direction: column-reverse;
    scrollbar-width: none;
    gap: 1rem;
}

.messages::-webkit-scrollbar {
    display: none;
}

.message {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: .25rem;
}

.message.sent {
    align-items: flex-end;
}

.message.received {
    align-items: flex-start;
}

.messageBody{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 60%;
    padding: .5rem;
    font-weight: 500;
    font-size: .875rem;
    overflow-wrap: anywhere;
    white-space: pre-line;
    padding-bottom: .5rem;
    font-family: var(--primary-font);
    text-align: left;
    align-items: flex-start;
}

.messageBody.sent {
    background-color: #EBFEF9;
    border-radius: 16px 16px 0px 16px;
    align-items: flex-start;
}

.messageBody.received {
    background-color: var(--background-color);
    border-radius: 16px 16px 16px 0px;
    align-items: flex-start;
}

.messageBody.received.tutor {
    background-color: var(--accent);
    border: 1px solid var(--accent);
    border-radius: 16px 16px 16px 0px;
    align-items: flex-start;
    color: white;
}

.messageBody.received.teacher {
    background-color: var(--accent);
    border: 1px solid var(--accent);
    border-radius: 16px 16px 16px 0px;
    align-items: flex-start;
    color: white;
}

.messageBody.received.admin {
    background-color: var(--accent);
    border: 1px solid var(--accent);
    border-radius: 16px 16px 16px 0px;
    align-items: flex-start;
    color: white;
}

.messageDate {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 500;
    font-size: 0.875rem;
    color: rgba(var(--background-color-rgb), 75%);
}

.messageName {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .25rem;
}

.tutorIcon {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

.messageInputContainer {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    background-color: rgba(var(--background-color-rgb), 4%);
    border-radius: 8px;
    align-items: center;
}

.innerInput {
    display: flex;
    flex-direction: row;    
    align-items: center;
    gap: .5rem;
}

.messageInput {
    font-family: var(--primary-font);
    background-color: transparent;
    border: none;
    color: var(--text-color);
    font-size: 1rem;
    width: 100%;
    padding: 1rem;
    color: white;
    resize: none;
    min-height: 1.5rem;
    max-height: 5.5rem;
}

.messageInput::placeholder {
    color: rgba(var(--background-color-rgb), 20%);
    font-family: var(--primary-font);
}

.messageInput:focus {
    outline: none;
}

.send {
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    background-color: var(--primary);
    border-radius: 8px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.send:disabled {
    cursor: not-allowed;
    background-color: rgba(var(--text-color-rgb), 20%);
}

.fileButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left: 1rem;
}

.fileButton > svg > path {
    fill: white;
}

.innerFile {
    display: flex;
    width: 100%;
    padding: 0.5rem;
    border-radius: 12px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    /* background-color: var(--background-color); */
    position: relative;
}

.innerFile video::-webkit-media-controls-panel {
    display: block !important;
    opacity: 1 !important;}

.innerFile video::-webkit-media-controls {
    display: block !important;
}



.closeFile{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--background-color);
    border-radius: 20px;
    padding: 4px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.player {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.playerWrapper{
    display: flex;
    border-radius: 8px;
    overflow: hidden;
}

.fileImage {
    aspect-ratio: 16/9;
    width: 100%;
    height: auto;
    object-fit: contain;
    /* background-color: var(--background-secondary-color); */
    border-radius: 8px;
}

.file {
    display: flex;
    flex-direction: row;
    padding: .5rem 1rem;
    border: 1px solid rgba(255,255,255,12%);
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex: 1;
    background-color: #212121;
}

.file.light {
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    background-color: var(--background-color);
}

.fileInfo {
    display: flex;
    flex-direction: column;
    gap: .3rem;
}

.fileName {
    font-weight: 700;
    font-size: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1.2rem; /* (line-height * numero di righe) + eventuali padding/margini */
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    overflow-wrap: anywhere;
}

.fileName.light {
    color: var(--text-color-rgb);
}

.fileSize {
    font-weight: 700;
    font-size: 0.75rem;
    color: rgba(255,255,255, 48%);
}

.fileSize.light {
    color: rgba(var(--text-color-rgb), 48%);
}

.deleteFile {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid rgba(var(--sf-red-rgb), 80%);
    border-radius: 10px;
    width: 32px;
    height: 32px;
    background: none;
    cursor: pointer;
    color: var(--sf-red);
}

.imageLoader {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.messageImage {
    width: 100%;
    max-width: 350px;
    object-fit: contain;
    border-radius: 8px;
}

.fullScreenImageContainer {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--text-color-rgb), 80%);
    z-index: 999;
}

.fullImageContainer {
    width: fit-content;
    height: 100%;
    padding: 5%;
    object-fit: contain;
}

.fullScreenImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.downloadFile {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #2FC7A0;
}