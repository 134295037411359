.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--background-secondary-color);
  align-items: center;
}

.section {
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding: 2rem 0;
}

.sectionInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 3rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.testContainer {
  flex-grow: 1;
  background-color: var(--background-color);
  border-radius: 20px;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initialCover,
.completedTest {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 3rem;
  width: 100%;
}

.name {
  font-weight: 800;
  font-size: 1.625rem;
}

.description {
  font-weight: 600;
  font-size: 1.25rem;
  color: rgba(var(--text-color-rgb), 80%);
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: fit-content;
  gap: 1rem;
  align-self: center;
}

.requests {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.label {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgba(var(--text-color-rgb), 40%)
}

.card {
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  padding: .5rem;
  border-radius: 14px;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: .3rem;
  max-width: 256px;
  width: 100%;
}

.top {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: .3rem;
}

.button {
  display: flex;
  justify-content: center;
  max-width: 200px;
}

.button svg {
  min-width: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  flex-wrap: wrap;
  align-items: center;
}


.initialTest {
  width: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  transition: .2s all ease-in-out;
}

.initialTest.started {
  left: 0;
}

.chartLabels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: .5rem;
}

.chartData {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.chartCell {
  background: rgba(var(--text-color-rgb), 4%);
  padding: .5rem 1rem;
  border-radius: 8px;
  overflow: hidden;
  flex-grow: 1;
}

.chartRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.chartColor {
  height: 14px;
  width: 14px;
  border-radius: 30px;
}

.chartColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.chartDataTitle {
  font-family: var(--primary-font);
  font-weight: 800;
}

.chartDataSubitle {
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 0.8rem;
}

.circularChartColumn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.icon>svg {
  min-width: 20px;
  min-height: 20px;
}

.expiredContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.expired {
  font-weight: 700;
  font-size: 1.25;
  color: var(--secondary);
}

@media screen and (max-width: 540px) {

  .sectionInner {
    padding: 1rem;
    flex-direction: column;
  }

  .splide {
    display: flex;
    width: 100%;
    max-width: calc(100vw - 2rem);
  }

  .slide {
    display: flex;
    width: 100%;
    max-width: calc(100vw - 2rem);
  }

  .initialCover {
    padding: 1rem;
  }

  .middle {
    width: 100%;
  }

  .card {
    max-width: none;
  }
}