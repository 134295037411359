.title{
    font-family: var(--primary-font);
    font-weight: 700;
    font-size: 2rem;
}

.headline{
    font-family: var(--primary-font);
    font-weight: 700;
    font-size: 0.8rem;
    color: rgba(var(--text-color-rgb), 40%)
}

.subtitle{
    font-family: var(--primary-font);
    font-weight: 700;
    font-size: 1.5rem;
}

.subheadline{
    font-family: var(--primary-font);
    font-weight: 700;
    font-size: 1.1rem;
}

.body{
    font-family: var(--primary-font);
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
}

.caption{
    font-family: var(--primary-font);
    font-weight: 400;
    font-size: 0.6rem;
}