.container {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
}

.gradientLeft{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background: linear-gradient(90deg, var(--background-color) 0%, rgba(255,255,255,0) 100%);
    z-index: 2;
}

.gradientRight{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background: linear-gradient(270deg, var(--background-color) 0%, rgba(255,255,255,0) 100%);
    z-index: 2;
}

.track {
    display: flex;
    position: relative;
    left: 0;
    align-items: center;
    height: 80px;
    min-width: 100%;
    width: fit-content;
    height: 100%;
    z-index: 1;
    justify-content: center;
}

.track.animated {
    position: absolute;
    justify-content: flex-start;
    animation: slide 30s linear infinite;
}

.card {
    display: flex;
    width: 30%;
    min-height: 120px;
    height: 120px;
    border-radius: 12px;
    padding: 1rem;
    justify-content: center;
    align-items: center;
}

.card.animated {
    min-width: 250px;
    width: 250px;
}

.card > img {
    width: 100%;
    object-fit: contain;
    max-height: 40px;
}


@media(hover: hover){
    .track:hover{
        animation-play-state: paused;
    }
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-33.33%);
    }
}