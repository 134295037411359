.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
}

.container.error input {
    border: 1px solid var(--sf-red);
}

.passwordInput {
    padding-right: 2.5rem !important;
}

.showPassword {
    position: absolute;
    right: .5rem;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: rgba(var(--text-color-rgb), 12%);
    display: flex;
    width: 20px;
    justify-content: center;
}

.searchIcon {
    position: absolute;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    left: 1.3rem;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: rgba(var(--text-color-rgb), 40%);
    display: flex;
    width: 14px;
    justify-content: center;
}

.searchInput {
    display: flex;
    padding: .9rem;
    padding-left: 2.3rem;
    width: 100%;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 1rem;
    box-sizing: border-box;
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.5%;
    background-color: transparent;
}

.searchInput::placeholder {
    font-family: var(--primary-font);
    font-weight: 500;
    opacity: 70%;
}

.searchInput:focus {
    outline: none;
    border: 1px solid var(--primary);
    transition: ease-in-out .2s all;
}

.searchInput:hover {
    outline: none;
    border: 1px solid var(--primary);
    transition: ease-in-out .2s all;
}

.roundedInput {
    display: flex;
    padding: .9rem;
    width: 100%;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 1rem;
    box-sizing: border-box;
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.5%;
}

.roundedInput.error {
    border: 1px solid var(--sf-red);
}

.roundedInput.error:focus {
    border: 1px solid var(--sf-red);
}

.roundedInput::placeholder {
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 1rem;
    opacity: 40%;
}

.roundedInput:focus {
    outline: none;
    border: 1px solid var(--primary);
    transition: ease-in-out .2s all;
}

.roundedInput:hover {
    outline: none;
    border: 1px solid var(--primary);
    transition: ease-in-out .2s all;
}

.roundedInput:disabled {
    opacity: 0.8;
}

.suggestions {
    display: flex;
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    max-height: 150px;
    z-index: 99;
    background-color: white;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 0 0 1rem 1rem;
    font-family: var(--primary-font);
    font-weight: 700;
    letter-spacing: 0.5%;
    flex-direction: column;
    overflow: scroll;
    padding-top: 20px;
}

.suggestion {
    border: none;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    background-color: transparent;
    padding: 1rem;
    text-align: left;
    font-family: var(--primary-font);
    font-weight: 700;
    cursor: pointer;
}

@media (hover: hover) {
    .showPassword:hover {
        color: var(--primary);
    }

    .roundedInput:disabled:hover {
        cursor: not-allowed;
        border: 1px solid rgba(var(--text-color-rgb), 12%);
    }
}
