.container {
    display: flex;
    height: 48px;
    width: 48px;
    border-radius: 24px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.notificationIcon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    pointer-events: none;
}

.dropdown {
    display: flex;
    position: absolute;
    width: 390px;
    bottom: -102px;
    height: fit-content;
    right: 85px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 0px 1px 1px rgba(var(--text-color-rgb), 12%);
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.dropdown:before {
    content: '';
    position: absolute;
    top: -15px;
    right: 35px;
    transform: rotate(180deg);
    width: 0;
    height: 0;
    border: 16px solid transparent;
    border-top-color: white;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
    filter: drop-shadow(0px 1px 1px rgba(var(--text-color-rgb), 12%));
}

.badge {
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: var(--secondary);
    color: white;
    font-weight: 700;
    font-size: 0.75rem;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid white;
    pointer-events: none;
}


.notification {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.inner {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.info {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 0.875rem;
    font-weight: 600;
    color: rgba(var(--text-color-rgb), 72%);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.title.read {
    color: rgba(var(--text-color-rgb), 56%);
}

.body {
    font-size: 0.875rem;
    font-weight: 800;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3.6em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.body.read {
    color: rgba(var(--text-color-rgb), 40%);
    font-weight: 500;
}

.thumbnail {
    height: 42px;
    width: 62px;
    min-width: 62px;
    min-height: 42px;
    object-fit: cover;
    border-radius: 4px;
}

.time {
    font-size: 0.875rem;
    color: rgba(var(--text-color-rgb), 40%);
    font-weight: 600;
    display: flex;
    flex-direction: row;
    gap: .15rem;
    align-items: center;
}

.clockIcon > g > path {
    stroke: rgba(var(--text-color-rgb), 40%);
}

.clockIcon {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    transform: translateY(1px);
}