.container{
    display: flex;
    flex-direction: row;
    border-radius: 14px;
    background-color: white;
    overflow: hidden;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    padding: 1rem;
    gap: 1rem;
    width: 100%;
}

.expired {
    filter: grayscale(1);
    opacity: 0.85;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    gap: 1rem;
}

.right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.thumbnail{
    display: flex;
    max-width: 250px;
    aspect-ratio: 16 / 9;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
}

.thumbnail > img {
    display: flex;
    object-fit: contain;
    width: 100%;    
}


.info{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: .5rem;
}

.description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3.6em; /* (line-height * numero di righe) + eventuali padding/margini */
    overflow: hidden;
    text-overflow: ellipsis;
}

.progressContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.progressInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.numbers {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.number {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    align-items: center;
    font-weight: 700;
}

.percentage {
    color: var(--tertiary);
    font-size: 1rem;
    font-weight: 700;
}

.progressBar {
    background-color: rgba(0,0,0,0.1);
    height: 10px;
    width: 100%;
    border-radius: 10px;
    position: relative;
}

.progressFill {
    position: absolute;
    left: 0;
    top: 0;
    height: 10px;
    border-radius: 10px;
    background: linear-gradient(to right, #16C0FB 0%, var(--primary) 100%);
}

@media screen and (max-width: 540px) {
    .container {
        flex-direction: column;
    }

    .thumbnail {
        width: 100%;
        height: 100%;
        aspect-ratio: 16/9;
        max-width: none;
    }
}
