.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  padding-top: 0;
  padding-bottom: 2rem;
  width: 100%;
}

.icon {
  width: 120px;
  height: 120px;
}

.title {
  font-size: 1.5rem;
  font-weight: 800;
  text-align: center;
}

.text {
  font-weight: 500;
  text-align: center;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}