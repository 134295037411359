label {
    --accent-color: var(--primary);
    display: flex;
    flex-direction: column;
    width: 30px;
    cursor: pointer;
    position: relative;
}

label span {
    background: var(--accent-color);
    border-radius: 10px;
    height: 3px;
    margin: 3px 0;
    transition: .2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}


span:nth-of-type(1) {
    width: 100%;

}

span:nth-of-type(2) {
    width: 100%;
}


span:nth-of-type(3) {
    width: 100%;

}


#hamburgerCheckbox {
    display: none;
}


#hamburgerCheckbox:checked~span:nth-of-type(1) {
    transform-origin: bottom;
    width: 50%;
    transform: rotatez(45deg) translate(4px, 0px)
}


#hamburgerCheckbox:checked~span:nth-of-type(2) {

    transform-origin: top;
    width: 98%;
    transform: rotatez(-45deg)
}


#hamburgerCheckbox:checked~span:nth-of-type(3) {

    transform-origin: bottom;
    width: 50%;
    transform: translate(13px, -5px) rotatez(45deg);

}

.warningBadge {
    display: flex;
    position: absolute;
    color: var(--sf-red);
    top: -0.25rem;
    right: -0.25rem;
    background-color: white;
    border-radius: 50%;
}