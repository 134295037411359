.step {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.actions {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
    background-color: var(--background-color);
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
}

.content {
    display: flex; 
    flex-direction: row;
    gap: .85rem;
    padding-bottom: 4rem;
    width: 100%;
}

.hourRanges {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.hourRange {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    cursor: pointer;
}

.hourRange:hover {
    border: 1px solid var(--primary);
}

.hourRange.selected {
    border: 1px solid var(--primary);
    background-color: rgba(var(--primary-rgb), 12%);
}

.hourRangeTitle {
    color: var(--primary);
    font-weight: 700;
    font-size: 0.875rem;
    text-transform: uppercase;
}

.hourRangeDescription {
    font-weight: 800;
    white-space: nowrap;
}

.contactOptions {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.contactOption {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    padding: 1rem;
    border-radius: 1rem;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    cursor: pointer;
    color: var(--primary);
    flex: 1;
}

.contactOption:hover {
    border: 1px solid var(--primary);
}

.contactOption.selected {
    border: 1px solid var(--primary);
    background-color: rgba(var(--primary-rgb), 12%);
}

.contactOptionTitle {
    color: var(--primary);
    font-weight: 700;
    font-size: 0.875rem;
}

.contactOptionIcon {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

.recap {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
}

.recapRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .2rem;
}

@media screen and (max-width: 540px) {
    
    .content {
        flex-direction: column;
    }

}