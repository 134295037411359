.gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
}

.thumbnail {
    object-fit: contain;
    padding: 1rem;
    height: 160px;
    z-index: 1;
}

.title {
    font-size: 1.25rem;
    font-weight: 800;
    width: 100%;
    text-align: center;
    padding: 1rem;
}

.tags {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    flex-wrap: wrap;
    gap: .5rem;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: rgba(var(--text-color-rgb), 12%);
}