.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-top: 2rem;
}

.text {
    font-size: 1.125rem;
    /* font-weight: 600; */
}

.answer,
.answerSolution {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-radius: 12px;
    border: 1px solid rgba(var(--text-color-rgb), 10%);
    padding: 1.5rem 2rem;
    width: 100%;
    transition: all .2s ease;
    position: relative;
    /* font-weight: 600; */
}

.answerSolution.wrong {
    background-color: rgba(var(--sf-red-rgb), 6%);
    border: 1.5px solid rgba(var(--sf-red-rgb), 100%);
}

.badges {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: .5rem;
    align-items: center;
}

.noAnswerIcon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: calc(-24px - 8px);
    top: calc(50% - 12px);
}

@media(hover:hover) {
    .answer:hover {
        border: 1.5px solid var(--sf-light-green);
    }
}

@media screen and (max-width: 540px) {

    .answer,
    .answerSolution {
        padding: 1rem;
        flex-direction: column;
        gap: 1rem;
    }
}