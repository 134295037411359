.container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--background-secondary-color);
    flex-wrap: nowrap;
}

.section {
    background-color: var(--background-secondary-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.sectionInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 3rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.playerWrapper {
    display: flex;
    aspect-ratio: 21 / 9;
    border-radius: 12px;
    overflow: hidden;
    height: fit-content;
    padding-bottom: 1rem;
    width: 100%;
}

.player {
    border-radius: 12px;
    overflow: hidden;
    max-width: 98vw;
    width: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.headerActions {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.rating {
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    overflow: hidden;
    gap: 0.2rem
}

.like {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: .7rem 1rem;
    color: var(--primary);
    transition: all .1s ease-in-out;
}

.dislike {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: .7rem 1rem;
    color: var(--secondary);
    transition: all .1s ease-in-out;
}

.like > svg {
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
}

.dislike > svg {
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
}

.description {
    white-space: pre-line;
    max-height: 7rem;
    overflow: hidden;
    position: relative;
    transition: all .2s ease-in-out;
    padding-bottom: 1rem;
    font-family: var(--primary-font);
}

.description.full {
    max-height: calc(100%);
    transition: all .2s ease-in-out;
}

.gradient {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(0deg, rgba(var(--background-secondary-color-rgb), 100%) 0%, rgba(var(--background-color-rgb), 0%) 100%);
}

.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .5rem;
    width: 100%;
}

.videos {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    position: relative;
}

@media (hover:hover){
    .like:hover > svg {
        transform: scale(1.2) rotate(-10deg);
        transition: all .1s ease-in-out;
    }

    .dislike:hover > svg {
        transform: scale(1.2) rotate(10deg);
        transition: all .1s ease-in-out;
    }
}

@media screen and (max-width: 768px) {

    .section {
        padding: 2rem 0;
    }

    .sectionInner {
        padding: 0 2rem;
    }
}

@media screen and (max-width: 540px) {

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 1rem;
    }

    .playerWrapper {
        display: flex;
        aspect-ratio: 16 / 9;
    }

    .header {
        flex-direction: column;
        gap: 1rem;
    }

    .headerActions {
        width: 100%;
    }

    .videos {
        flex-direction: column;
    }

}